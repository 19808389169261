import { type FC } from "react";
import { Wrapper } from "components/Common/LineItemGrid/Modification/modification.styles";
import { RequiredSpecificationLabel } from "../../../NewOrders/AddLineItem/constants.ts";
import { AddLineItemRequiredDimensions, ModRequiredContent } from "../../../NewOrders/AddLineItem/AddLineItemStyles.ts";
import { models } from "types/api/viewModels.ts";

export interface ModificationProps {
	description?: string | null;
	extendedPrice?: number;
	lineNumber: string | null | undefined;
	listPrice?: number;
	title?: string | null;
	dimensionDescription?: string | null;
	dimensionValue?: number;
	values?: models["PendingValueViewModel"][] | null | undefined;
	requiredCategory?: models["PendingRequiredCategoryValueViewModel"] | null;
}

const Modification: FC<ModificationProps> = (props) => {
	const {
		description,
		extendedPrice,
		requiredCategory,
		lineNumber,
		listPrice,
		title,
		dimensionDescription,
		dimensionValue,
		values
	} = props;
	const rowCount = typeof extendedPrice !== "undefined" && typeof listPrice !== "undefined" ? 2 : 1;

	return (
		<Wrapper rowCount={rowCount}>
			<div>
				<div data-testid="line-item-grid-modifications-line-number">{lineNumber}</div>
				<div>
					{title && (
						<div data-testid="line-item-grid-modifications-line-title">
							{title} - {description}
						</div>
					)}
					{values?.map((value) => (
						<div key={value.value}>
							<div data-testid="line-item-grid-modifications-line-description">
								{value.description} <span>{`${value.value}"`}</span>
							</div>
						</div>
					))}
					{dimensionDescription && (
						<AddLineItemRequiredDimensions>
							<div data-testid="line-item-grid-modifications-line-required-dimension-label">
								{RequiredSpecificationLabel}
								<span data-testid="line-item-grid-modifications-line-required-dimension-description">
									{dimensionDescription}
								</span>
								<span data-testid="line-item-grid-modifications-line-required-dimension-value">
									{`${dimensionValue}"`}
								</span>
							</div>
						</AddLineItemRequiredDimensions>
					)}
					{requiredCategory && requiredCategory?.sku && (
						<ModRequiredContent>
							<div data-testid="line-item-grid-modifications-line-hinge-pattern-label">
								{RequiredSpecificationLabel}
								{requiredCategory?.categoryName}
								<span data-testid="line-item-grid-modifications-required-category-sku">
									{requiredCategory.sku}
								</span>
							</div>
						</ModRequiredContent>
					)}
				</div>
			</div>
			{/* Confirmed with Sarah that we do not want to display extended and list pricing on modifications */}
			{/* Jen asked that we comment this code out should we want to restore it later */}
			{/* <div> */}
			{/*	<div data-testid="line-item-grid-modifications-line-list-price"> */}
			{/*		{listPrice?.toLocaleString("en-US", { */}
			{/*			style: "currency", */}
			{/*			currency: "USD" */}
			{/*		})} */}
			{/*	</div> */}
			{/*	<div data-testid="line-item-grid-modifications-line-extended-price"> */}
			{/*		{extendedPrice?.toLocaleString("en-US", { */}
			{/*			style: "currency", */}
			{/*			currency: "USD" */}
			{/*		})} */}
			{/*	</div> */}
			{/* </div> */}
		</Wrapper>
	);
};

export default Modification;
