import { Button } from "@mui/material";
import {
	CreateYourItemSubtitleText,
	DraftANewOrderText,
	FindOriginalOrderText,
	FindOriginalText,
	ManualOrderEntryText,
	ReplacementOrAddOnText
} from "constants/text";
import {
	DraftNewOrderHeader,
	GridChildTwoContainer,
	GridChildTwoStyles,
	NewOrdersContentContainer,
	NewOrdersHeaderGrid,
	NewOrdersLayoutContainer,
	OrderOrAddOnText,
	UnsetSecondaryCopy
} from "components/NewOrders/NewOrders.styles";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router";
import { NewOrderModalTracking } from "data/api/v1";
import { useDispatch } from "react-redux";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import SaveDraftTable from "components/NewOrders/SaveDraft/SaveDraftTable/SaveDraftTable.tsx";
import AddGlobalAttributes from "components/NewOrders/AddGlobalAttributes/AddGlobalAttributes";
import GlobalFormProvider from "components/NewOrders/AddGlobalAttributes/forms/GlobalFormProvider";
import ImportCSV from "components/NewOrders/ImportCSV/ImportCSV";
import NewOrdersDialog from "components/NewOrders/NewOrdersDialog/NewOrdersDialog";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { useEffect } from "react";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";

const NewOrderPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleFindOriginalOrder = () => {
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.CSV_UPLOAD));
		navigate("/projects");
	};

	useEffect(() => {
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
	}, [dispatch]);

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				includeDetails={false}
				includeStepper={false}
				isNewOrderPage
			/>

			<NewOrdersContentContainer>
				<DraftNewOrderHeader>{DraftANewOrderText}</DraftNewOrderHeader>

				<NewOrdersHeaderGrid>
					<ImportCSV />
					<GridChildTwoStyles>
						<GridChildTwoContainer>
							<OrderOrAddOnText>{ReplacementOrAddOnText}</OrderOrAddOnText>
							<Button
								onClick={handleFindOriginalOrder}
								variant="outlined"
								startIcon={
									<ArrowOutwardIcon
										fontSize="small"
										sx={{ marginBottom: "2px" }}
									/>
								}
								data-id="new-order-find-original-order-button"
								sx={{
									display: "flex",
									alignItems: "center",
									height: "fit-content",
									width: "max-content"
								}}
							>
								{FindOriginalOrderText}
							</Button>
							<UnsetSecondaryCopy>{FindOriginalText}</UnsetSecondaryCopy>
						</GridChildTwoContainer>
						<div style={{ height: 20, background: "#f7f7f7" }} />
						<GridChildTwoContainer>
							<OrderOrAddOnText>{ManualOrderEntryText}</OrderOrAddOnText>
							<GlobalFormProvider>
								<AddGlobalAttributes showAddItem />
							</GlobalFormProvider>
							<UnsetSecondaryCopy>{CreateYourItemSubtitleText}</UnsetSecondaryCopy>
						</GridChildTwoContainer>
					</GridChildTwoStyles>
				</NewOrdersHeaderGrid>

				<SaveDraftTable />
			</NewOrdersContentContainer>
			<NewOrdersDialog />
		</NewOrdersLayoutContainer>
	);
};

export default NewOrderPage;
