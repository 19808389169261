import { FormControlLabel } from "@mui/material";
import { StyledSwitch } from "components/Common/Toggle/toggle.styles.ts";

interface CWGToggleProps {
	label: string;
	isToggledOn: boolean;
	onToggleChange: () => void;
	testId: string;
}

const Toggle = ({ label, isToggledOn, onToggleChange, testId }: CWGToggleProps) => {
	return (
		<FormControlLabel
			control={
				<StyledSwitch
					data-testid={testId}
					checked={isToggledOn}
					onClick={onToggleChange}
					size="small"
				/>
			}
			label={label}
			sx={{
				width: "100%",
				margin: 0
			}}
		/>
	);
};

export default Toggle;
