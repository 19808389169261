import { ORDER_PARENT_BRANDS } from "../constants/orderParentBrands";
import { UNIQUE_BRAND_NAMES } from "constants/uniqueBrandNames";
import { convertToTitleCase } from "./string";
import { NoDesignerText, UnavailableDataPlaceholderText } from "../constants/text.ts";

export const filterSpecificBrands = (parentBrand: string | undefined | null) => {
	const uniqueBrand = UNIQUE_BRAND_NAMES.find((i) => i.input === parentBrand);
	if (uniqueBrand) {
		return uniqueBrand.output;
	} else {
		return convertToTitleCase(parentBrand);
	}
};

export const brandCheck = (parentBrand: any) => {
	return !!(parentBrand !== "UNKNOWN" && ORDER_PARENT_BRANDS.find((brand: any) => brand === parentBrand));
};

export const displayDraftDesigner = (
	designerName: string | undefined | null,
	designerNumber: string | undefined | null,
	isFilter?: boolean
) => {
	if (
		(designerName && designerName !== UnavailableDataPlaceholderText) ||
		(designerNumber && designerNumber !== UnavailableDataPlaceholderText)
	) {
		return [designerName, designerNumber].filter(Boolean).join(" - ");
	} else {
		return isFilter ? NoDesignerText : UnavailableDataPlaceholderText;
	}
};

export const displayDesigner = (designer: any) => {
	if (typeof designer === "string") {
		return designer;
	}

	if (designer?.firstName && designer?.lastName) {
		return `${designer.firstName} ${designer.lastName} - ${designer.designerNumber}`;
	}

	if (designer?.firstName) {
		return `${designer.firstName} - ${designer.designerNumber}`;
	}

	if (designer?.lastName) {
		return `${designer.lastName} - ${designer.designerNumber}`;
	}

	return designer?.designerNumber;
};

export const sortBy = (initOrders: any, sortBy: string = "order-date") => {
	const orders = [...initOrders];

	if (orders.length > 1) {
		const alpha = orders.sort((a: any, b: any) => {
			// Since alphabet is second tier, orders gets sorted alphabetically first
			const jobNameA = a.jobName || "";
			const jobNameB = b.jobName || "";
			if (jobNameA < jobNameB) return -1;
			if (jobNameA > jobNameB) return 1;
			const orderNumberA = parseInt(a.orderNumber);
			const orderNumberB = parseInt(b.orderNumber);
			return orderNumberA - orderNumberB;
		});
		if (sortBy === "order-date") {
			return alpha.sort((a: any, b: any) => {
				const dateA = new Date(a.orderDate).getTime();
				const dateB = new Date(b.orderDate).getTime();
				return dateB - dateA;
			});
		}
	}
	return orders;
};
