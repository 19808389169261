import { number, object, ObjectSchema, string } from "yup";
import { ProductSelectFormError } from "./constants.ts";

export interface SkuAndDescription {
	sku: string | null | undefined;
	description: string | null | undefined;
}

export interface FormSchema {
	productSelect?: SkuAndDescription | null;
	productSelectQuantity: number | undefined;
	editModificationSelect: string | undefined;
}

const productViewModelSchema = object({
	sku: string().required(),
	description: string().required()
});

export const addLineItemSchemaObject = {
	productSelect: productViewModelSchema.required(ProductSelectFormError),
	productSelectQuantity: number(),
	editModificationSelect: string()
} as const;

export const schema: ObjectSchema<FormSchema> = object(addLineItemSchemaObject);
