import { ChangeEvent, Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { trackGA4Event } from "utils/googleAnalytics";
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { OrderSearchContainer, OrderSearchInputLine, OrderSearchTextField } from "./OrderSearch.styles";
import { ExactMatchText, PartialMatchText, ShowMeText } from "components/NewOrders/constants";
import {
	OrderLimitMessage,
	minCharCountText,
	myOrdersText,
	searchBarHelperText,
	searchHeaderButtonText,
	searchOrdersText
} from "constants/text";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { StyledSearchOrderLimitHelperText } from "components/Form/HelperText/helperText.styles";
import { DatePicker } from "components/DatePicker";
import { DateRange } from "pages/OrderPages/OrderListPage/OrderListPage";

interface OrderSearchProps {
	isCareRole: boolean;
	setCurrentSearch: Dispatch<SetStateAction<string>>;
	setPartialMatch: Dispatch<SetStateAction<string>>;
	partialMatch: string;
	currentSearch: string;
	invokeSearch: () => {};
	hasSearchQueryParam: boolean;
	isLoading: boolean;
	isPartialDataset: boolean;
	searchDates: DateRange;
	setSearchDates: React.Dispatch<React.SetStateAction<DateRange>>;
}

const OrderSearch = ({
	isCareRole,
	setCurrentSearch,
	setPartialMatch,
	partialMatch,
	currentSearch,
	invokeSearch,
	hasSearchQueryParam,
	isLoading,
	isPartialDataset,
	searchDates,
	setSearchDates
}: OrderSearchProps) => {
	const didInitialLoad = useRef(false);
	const didReset = useRef(false);
	const didSearch = useRef(false);
	const didType = useRef(false);

	if (!didType.current && currentSearch?.length) {
		didType.current = true;
	}

	const searchLength = currentSearch?.trim().length;
	const [hasSearchValidationError, setHasSearchValidationError] = useState(false);

	const handleOnSearch = useCallback(() => {
		if (didType.current && searchLength < 3 && (searchLength > 0 || isCareRole)) {
			setHasSearchValidationError(true);
			return;
		}

		trackGA4Event({
			event: "Search - Field",
			eventCategory: "search",
			eventAction: "type",
			eventLabel: `Summary - ${currentSearch}`
		});

		invokeSearch();
		didSearch.current = true;
	}, [currentSearch, invokeSearch, isCareRole, searchLength]);

	if (!didInitialLoad.current && !isCareRole && !hasSearchQueryParam) {
		didInitialLoad.current = true;
		handleOnSearch();
	}

	if (didReset.current) {
		didReset.current = false;
		if (didSearch.current) {
			didSearch.current = false;
			handleOnSearch();
		}
	}

	const handleSearchFieldOnKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Enter") {
				setHasSearchValidationError(searchLength < 3 && didType.current && (searchLength > 0 || isCareRole));
				handleOnSearch();
			} else {
				setHasSearchValidationError(false);
			}
		},
		[handleOnSearch, isCareRole, searchLength]
	);

	const handleSearchFieldOnChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => setCurrentSearch(e.target.value),
		[setCurrentSearch]
	);
	const handlePartialSearch = useCallback(
		(e: SelectChangeEvent) => {
			setPartialMatch(e.target.value);
		},
		[setPartialMatch]
	);

	const resetSearch = useCallback(() => {
		setCurrentSearch("");
		didReset.current = true;
	}, [setCurrentSearch]);

	const handleChange = (event: SelectChangeEvent) => {
		handlePartialSearch(event);
	};
	return (
		<OrderSearchContainer
			id="my-orders-header"
			data-testid="search-orders-header"
		>
			<h5>{myOrdersText}</h5>
			<OrderSearchInputLine isCareRole={isCareRole}>
				{isCareRole && (
					<FormControl size="small">
						<InputLabel>{ShowMeText}</InputLabel>
						<Select
							value={partialMatch}
							label={ShowMeText}
							onChange={handleChange}
							data-TestId="showme-search-dropdown"
						>
							<MenuItem value="false">{ExactMatchText}</MenuItem>
							<MenuItem value="true">{PartialMatchText}</MenuItem>
						</Select>
					</FormControl>
				)}
				<DatePicker
					partialMatch={partialMatch}
					searchDates={searchDates}
					setSearchDates={setSearchDates}
				/>
				<OrderSearchTextField
					size="small"
					required
					error={hasSearchValidationError}
					label={searchOrdersText}
					placeholder={searchBarHelperText}
					helperText={hasSearchValidationError && minCharCountText}
					data-testid="search-header-input-field"
					value={currentSearch}
					onChange={handleSearchFieldOnChange}
					onKeyDown={handleSearchFieldOnKeyDown}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="start"
								onClick={() => {
									resetSearch();
								}}
							>
								{currentSearch !== undefined && currentSearch.length >= 3 && (
									<CloseIcon cursor="pointer" />
								)}
							</InputAdornment>
						)
					}}
					InputLabelProps={{
						required: false,
						shrink: true
					}}
				/>

				<Button
					variant="contained"
					data-testid="search-header-button"
					data-id="search-header-button"
					onClick={handleOnSearch}
					aria-label="Search Orders"
					size="small"
				>
					{searchHeaderButtonText}
				</Button>
			</OrderSearchInputLine>

			{!isLoading && isPartialDataset && (
				<StyledSearchOrderLimitHelperText
					isLimitError
					data-testid="order-list-order-count-threshold"
				>
					<ErrorOutlineIcon
						data-testid="order-list-order-count-threshold-icon"
						aria-label="Warning"
					/>
					<span>{OrderLimitMessage}</span>
				</StyledSearchOrderLimitHelperText>
			)}
		</OrderSearchContainer>
	);
};

export default OrderSearch;
