import styled from "@emotion/styled";
import { LinkButton } from "../../Common/Link";
import { Button } from "@mui/material";

interface AddModificationStylesProps {
	multipleRanges: boolean;
}

export const AddModificationButton = styled(Button)({
	display: "flex",
	fontSize: "0.9rem",
	gap: "8px",
	fontWeight: 500,
	textTransform: "uppercase"
});

export const AddModificationTypeWrapper = styled.div<AddModificationStylesProps>(({ multipleRanges, theme }) => ({
	display: "flex",
	flexDirection: "row",
	gap: 16,
	width: "100%",
	"& > div": {
		width: "100%"
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column"
	}
}));

export const ModificationGroup = styled.div({
	alignItems: "flex-start",
	display: "flex",
	flexDirection: "column",
	gap: 16,
	"& > div": {
		width: "100%"
	},
	"& > h6:first-of-type": {
		margin: 0
	},
	width: "100%"
});

export const ModificationSkeletonContainer = styled.div({
	marginBottom: 8
});

export const AddModificationRemoveButton = styled(LinkButton)(({ theme }) => ({
	height: 40,
	[theme.breakpoints.down("md")]: {
		alignSelf: "center",
		height: "auto"
	}
}));

export const AddModificationValues = styled.div(({ theme }) => ({
	...theme.project.typography.caption,
	color: theme.project.colors.fontColors.secondary,
	paddingTop: 3
}));
