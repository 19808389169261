import { AutoCompleteContainer, SelectionDetails } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { GlobalAttributeFields } from "../forms/schema";
import { NAOptionText, SelectAccountNumber, SelectBillToNumber } from "../../constants";
import { useLazyGetAccountsQuery } from "features/api/accountApi";
import { BillToInfoViewModel } from "data/api/v1/model/bill-to-info-view-model";
import { convertToTitleCase } from "utils/string";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { sortedAccounts, sortedBillTos } from "components/NewOrders/utils/NewOrderUtils.tsx";
import AccountInfo from "components/NewOrders/components/AccountInfo/AccountInfo.tsx";
import { useParams } from "react-router-dom";
import useInitOrderDetail from "hooks/useInitOrderDetail.ts";

interface StepFourProps {
	isAddOn: boolean;
	isUpdate?: boolean;
}

const StepFour = ({ isAddOn, isUpdate }: StepFourProps) => {
	const [trigger, { data: accounts, isLoading }] = useLazyGetAccountsQuery();
	const { control, getValues, watch, setValue } = useFormContext<GlobalAttributeFields>();
	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedShape = watch("shape");
	const selectedSpecies = watch("species");
	const selectedFinish = watch("finish");
	const selectedHingeType = watch("hinge");
	const selectedCaseColor = watch("caseColor");
	const selectedProfile = watch("profile");
	const selectedDistressing = watch("distressing");
	const selectedAccentColor = watch("accentColor");
	const selectedAccentApplication = watch("accentApplication");
	const selectedConstruction = watch("construction");
	const selectedPackaging = watch("packaging");
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);
	const fetchedProductLineAccounts = useRef<string[]>([]);

	const dispatch = useDispatch();
	const params = useParams();
	const { orderDetail } = useInitOrderDetail(params.orderId);

	const selectedAccount = watch("accountNumber");
	const selectedBillTo = watch("billToNumber");

	useEffect(() => {
		if (accounts && (isAddOn || isUpdate)) {
			const accountToFind = orderDetail ? orderDetail?.accountNumber : updatedGlobalAttributes?.accountNumber;
			const billToToFind = orderDetail ? orderDetail?.billToId : updatedGlobalAttributes?.billToId;
			const account = accounts.find((account) => account?.accountNumber === accountToFind);
			setValue("accountNumber", account);
			setValue(
				"billToNumber",
				account?.billTos?.find((billTo) => billTo?.billToId === billToToFind)
			);
		}
	}, [
		accounts,
		isAddOn,
		isUpdate,
		orderDetail,
		setValue,
		updatedGlobalAttributes?.accountNumber,
		updatedGlobalAttributes?.billToId
	]);

	useEffect(() => {
		const productLineCode = getValues()?.productLine?.code ?? "";
		if (productLineCode && accounts && !fetchedProductLineAccounts.current.includes(productLineCode)) {
			dispatch(newOrderActions.addProductLineAccountInfo([productLineCode, accounts]));
			fetchedProductLineAccounts.current.push(productLineCode);
		}
	}, [accounts, dispatch, getValues]);

	useEffect(() => {
		const productLineCode = isUpdate
			? updatedGlobalAttributes?.globals?.productLine?.code
			: getValues()?.productLine?.code;

		if (productLineCode) {
			trigger(productLineCode);
		}
	}, [getValues, isUpdate, trigger, updatedGlobalAttributes?.globals?.productLine?.code]);

	const descriptions = [
		selectedProductLine?.description ?? updatedGlobalAttributes?.globals?.productLine?.description,
		selectedDoorStyle?.description,
		selectedShape?.description,
		selectedProfile?.description,
		selectedHingeType?.description,
		selectedCaseColor?.description,
		selectedSpecies?.description,
		selectedFinish?.description,
		selectedDistressing?.description !== NAOptionText && selectedDistressing?.description,
		selectedAccentColor?.description !== NAOptionText && selectedAccentColor?.description,
		selectedAccentApplication?.description !== NAOptionText && selectedAccentApplication?.description,
		selectedConstruction?.description,
		selectedPackaging?.description
	]
		.filter(Boolean)
		.join(", ");

	return (
		<AutoCompleteContainer>
			{isAddOn ? (
				<>
					<AccountInfo
						variant="ACCOUNT"
						account={selectedAccount}
					/>
					<AccountInfo
						variant="BILLTO"
						billTo={selectedBillTo}
					/>
				</>
			) : (
				<>
					<SelectionDetails data-testid="global-attribute-modal-selection">
						<span>{descriptions}</span>
					</SelectionDetails>
					<Controller
						name="accountNumber"
						control={control}
						render={({ field: { onChange, value = null }, fieldState: { error } }) => (
							<AutoComplete
								required
								value={value}
								disableClearable
								isLoading={isLoading}
								label={SelectAccountNumber}
								getOptionLabel={(option) =>
									option?.accountNumber && option?.accountName
										? `${option.accountNumber} - ${option.accountName}`
										: (option?.accountNumber ?? "")
								}
								onChange={(_event, value) => {
									setValue("billToNumber", undefined);
									if (isUpdate) {
										const globalAttributesToBeUpdated = {
											accountNumber: value.accountNumber,
											accountId: value.accountId,
											accountName: value.accountName
										};
										dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
									}
									onChange(value);
								}}
								options={sortedAccounts(accounts) ?? []}
								dataTestId="select-account-number"
								isError={!!error}
								errorText={error?.message}
							/>
						)}
					/>

					<Controller
						name="billToNumber"
						control={control}
						render={({ field: { onChange, value = null }, fieldState: { error } }) => (
							<AutoComplete
								required
								value={value}
								disableClearable
								disabled={!selectedAccount}
								label={SelectBillToNumber}
								onChange={(_event, value) => {
									if (isUpdate) {
										const globalAttributesToBeUpdated = {
											billToId: value.billToId
										};
										dispatch(newOrderActions.updateGlobalAttributes(globalAttributesToBeUpdated));
									}
									onChange(value);
								}}
								getOptionLabel={(option: BillToInfoViewModel) => {
									const projectName =
										option.projectName && option.projectName.trim() !== ""
											? option.projectName + " - "
											: "";
									const logisticsMode = option.logisticsMode
										? " - " + convertToTitleCase(option.logisticsMode)
										: "";
									return option
										? `${projectName}${option.city}, ${option.state}${logisticsMode} - ${option.billToId}`
										: "";
								}}
								options={
									sortedBillTos(selectedAccount?.billTos?.filter((billTo) => billTo.isActive)) ?? []
								}
								dataTestId="select-bill-to-number"
								isError={!!error}
								errorText={error?.message}
							/>
						)}
					/>
				</>
			)}
		</AutoCompleteContainer>
	);
};

export default StepFour;
