import styled from "@emotion/styled";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

export const SidebarContainer = styled.div(({ theme }) => ({
	padding: 0,
	position: "fixed",
	backgroundColor: "var(--cwg-blue-900)",
	width: "var(--sidebar-width)",
	height: "100vh",
	[theme.breakpoints.down("lg")]: {
		width: "var(--full-width)",
		height: "var(--mobile-sidebar-height)",
		marginBottom: "1rem",
		padding: 0,
		zIndex: 99
	}
}));

export const SidebarIconBox = styled(Box)(({ theme }) => ({
	[theme.breakpoints.up("lg")]: {
		display: "none"
	}
}));

export const SidebarContainerMobile = styled.div({
	backgroundColor: "var(--cwg-blue-900)",
	width: "var(--full-width)",
	height: "auto",
	position: "absolute",
	top: "var(--mobile-sidebar-height)"
});

export const CloseMenuIcon = styled(CloseIcon)(({ theme }) => ({
	color: theme.project.colors.fontColors.contrast,
	height: "var(--mobile-sidebar-height)",
	[theme.breakpoints.down("lg")]: {
		marginRight: "1.25rem",
		"&:hover": {
			cursor: "pointer"
		}
	}
}));

export const SidebarMenuIcon = styled(MenuIcon)(({ theme }) => ({
	color: theme.project.colors.fontColors.contrast,
	height: "var(--mobile-sidebar-height)",
	[theme.breakpoints.down("lg")]: {
		marginRight: "1.25rem",
		"&:hover": {
			cursor: "pointer"
		}
	}
}));

interface NavWrapperProps {
	isMainNavOpen: boolean;
	isSubNavOpen: boolean;
}
export const NavWrapper = styled.div<NavWrapperProps>(({ theme, isMainNavOpen, isSubNavOpen }) => ({
	backgroundColor: "var(--cwg-blue-900)",
	display: "flex",
	"& > div:nth-of-type(1)": {
		display: "none"
	},
	"& > div:nth-of-type(2)": {
		width: "100%",
		"& > div:nth-of-type(n + 2)": {
			display: isMainNavOpen ? "block" : "none"
		}
	},
	[theme.breakpoints.up("lg")]: {
		"& > div:nth-of-type(1)": {
			display: isSubNavOpen ? "block" : "none",
			width: "100%"
		},
		"& > div:nth-of-type(2)": {
			display: isSubNavOpen ? "none" : "block",
			"& > div:nth-of-type(n + 2)": {
				display: "block"
			}
		}
	}
}));

export const BrandingWrapper = styled.div(({ theme }) => ({
	display: "flex",
	height: "var(--mobile-sidebar-height)",
	justifyContent: "space-between",
	width: "100%",
	[theme.breakpoints.up("lg")]: {
		height: "auto",
		"& > div:nth-of-type(2)": {
			display: "none"
		}
	}
}));
