import { combineReducers, configureStore } from "@reduxjs/toolkit";
import windowSettingsReducer from "features/reducers/settings/windowSettings.ts";
import cartReducer from "features/reducers/replacementOrder/cart.ts";
import impersonationSlice from "features/reducers/settings/impersonation.ts";
import submittingReplacementOrderSlice from "features/reducers/replacementOrder/submittingReplacementOrder.ts";
import submittedReplacementOrdersSlice from "features/reducers/replacementOrder/submittedReplacementOrders.ts";
import submittingNewOrderSlice from "features/reducers/newOrder/submittingNewOrder.ts";
import shippingDetailsSlice from "features/reducers/newOrder/shippingDetails.ts";
import newOrderSlice from "features/reducers/newOrder/newOrder.ts";
import { userApiSlice, userEmulationApiSlice } from "features/api/userApi.ts";
import { orderApiSlice } from "features/api/orderApi.ts";
import { imageApiSlice } from "features/api/imageApi.ts";
import { addressApiSlice } from "features/api/addressApi.ts";
import { accountApiSlice } from "features/api/accountApi.ts";
import { newOrderApiSlice } from "features/api/newOrderApi.ts";
import { designerApiSlice } from "features/api/designerApi.ts";
import { write } from "hooks/useSession";
import { globalsApiSlice } from "features/api/globalsApi.ts";

const rootReducer = combineReducers({
	windowSettings: windowSettingsReducer,
	cart: cartReducer,
	impersonation: impersonationSlice,
	submittingReplacementOrder: submittingReplacementOrderSlice,
	submittedReplacementOrders: submittedReplacementOrdersSlice,
	newOrder: newOrderSlice,
	submittingNewOrder: submittingNewOrderSlice,
	shippingDetails: shippingDetailsSlice,
	[orderApiSlice.reducerPath]: orderApiSlice.reducer,
	[userApiSlice.reducerPath]: userApiSlice.reducer,
	[userEmulationApiSlice.reducerPath]: userEmulationApiSlice.reducer,
	[imageApiSlice.reducerPath]: imageApiSlice.reducer,
	[addressApiSlice.reducerPath]: addressApiSlice.reducer,
	[newOrderApiSlice.reducerPath]: newOrderApiSlice.reducer,
	[accountApiSlice.reducerPath]: accountApiSlice.reducer,
	[designerApiSlice.reducerPath]: designerApiSlice.reducer,
	[globalsApiSlice.reducerPath]: globalsApiSlice.reducer
});

export function setupStore(preloadedState?: Partial<RootState>) {
	return configureStore({
		preloadedState,
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				.concat(orderApiSlice.middleware)
				.concat(userApiSlice.middleware)
				.concat(userEmulationApiSlice.middleware)
				.concat(imageApiSlice.middleware)
				.concat(addressApiSlice.middleware)
				.concat(accountApiSlice.middleware)
				.concat(newOrderApiSlice.middleware)
				.concat(designerApiSlice.middleware)
				.concat(globalsApiSlice.middleware)
	});
}

export const store = setupStore();

store.subscribe(() => {
	const {
		cart,
		impersonation,
		submittingReplacementOrder,
		submittedReplacementOrders,
		submittingNewOrder,
		newOrder,
		shippingDetails
	} = store.getState();
	write("_cart", cart);
	write("_impersonation", impersonation);
	write("_submittingReplacementOrder", submittingReplacementOrder);
	write("_submittedReplacementOrders", submittedReplacementOrders);
	write("_submittingNewOrder", submittingNewOrder);
	write("_newOrder", newOrder);
	write("_shippingDetails", shippingDetails);
});

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
