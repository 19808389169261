import { PropsWithChildren, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalAttributeFields, schema } from "./schema";
import { useGetFinishDataQuery, useGetStyleDataQuery } from "features/api/globalsApi.ts";

interface GlobalFormProviderProps extends PropsWithChildren<{}> {
	defaultValues?: Partial<GlobalAttributeFields>;
	isUpdate?: boolean;
}

const GlobalFormProvider = ({ children, defaultValues, isUpdate = false }: GlobalFormProviderProps) => {
	const [finishId, setFinishId] = useState<string>("");
	const [doorStyleId, setDoorStyleId] = useState<string>("");
	const { data: finishData } = useGetFinishDataQuery(finishId, { skip: !finishId });
	const { data: doorStyleData } = useGetStyleDataQuery(doorStyleId, { skip: !doorStyleId });

	const formMethods = useForm({
		mode: "onChange",
		resolver: yupResolver(schema),
		reValidateMode: "onChange",
		context: {
			hasShape: Boolean(doorStyleData?.shapes?.length !== 0),
			hasProfile: Boolean(doorStyleData?.cabinetProfiles?.length !== 0),
			hasHinge: Boolean(doorStyleData?.hingeTypes?.length !== 0),
			hasCase: Boolean(doorStyleData?.cases?.length !== 0),
			hasPackaging: Boolean(finishData?.packagings?.length !== 0),
			hasDistressing: Boolean(finishData?.distressings?.length !== 0),
			hasAccentColor: Boolean(finishData?.accentColors?.length !== 0),
			hasAccentApplication: Boolean(finishData?.accentApplications?.length !== 0),
			hasConstruction: Boolean(finishData?.constructions?.length !== 0),
			isUpdate: isUpdate
		}
	});

	const selectedFinish = formMethods.watch("finish");
	const selectedDoorStyle = formMethods.watch("doorStyle");

	useEffect(() => {
		if (selectedFinish?.id) {
			setFinishId(selectedFinish.id);
		}

		if (selectedDoorStyle?.id) {
			setDoorStyleId(selectedDoorStyle.id);
		}
	}, [selectedFinish?.id, selectedDoorStyle?.id]);

	return <FormProvider {...formMethods}>{children}</FormProvider>;
};

export default GlobalFormProvider;
