import { LinearProgress } from "@mui/material";
import {
	UploadCSVFileInfoProgressBar,
	UploadErrorWaringText,
	UploadModalCSVFileAttachmentViewBytes,
	UploadModalCSVFileInfoContainer,
	UploadModalCSVFileStatusText
} from "../NewOrders.styles";
import { formatBytes } from "utils/file";
import Close from "@mui/icons-material/Close";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { NewOrderImportFileError, NewOrderImportSelectionDoesntMatch, NewOrderUnsupportedFile } from "../constants";
import { FileSuccessText, NoAccountText } from "constants/text";

interface CSVFileUploadProgressProps {
	file: File;
	isUpdating?: boolean;
	hasError?: boolean;
	isAttemptedValidation?: boolean;
	errors?: any;
	isSuccess?: boolean;
	uploadNotFound?: boolean;
	uploadFileNull?: boolean;
	uploadNoConfigurations?: boolean;
	uploadUnsupportedFile?: boolean;
	fetchError?: boolean;
	uploadUnauthorized?: boolean;
	uploadGenericError?: boolean;
	removeFile: () => void;
}

const renderUploadStatus = (message: string, condition?: boolean) =>
	condition && <UploadErrorWaringText>{message}</UploadErrorWaringText>;

const CSVFileUploadProgress = ({
	file,
	isUpdating,
	errors,
	hasError,
	isAttemptedValidation,
	isSuccess,
	uploadNotFound,
	uploadFileNull,
	uploadGenericError,
	uploadNoConfigurations,
	uploadUnauthorized,
	uploadUnsupportedFile,
	fetchError,
	removeFile
}: CSVFileUploadProgressProps) => {
	return (
		<UploadModalCSVFileInfoContainer
			key={file.name}
			isError={hasError}
			isSuccess={isSuccess}
		>
			<p data-testid="new-order-csv-upload-selected-file-name">
				{file.name}
				<UploadModalCSVFileAttachmentViewBytes
					data-testid="new-order-csv-upload-selected-file-size"
					isError={hasError}
				>
					{formatBytes(file.size)}
				</UploadModalCSVFileAttachmentViewBytes>
			</p>
			{(isAttemptedValidation || isUpdating) && (
				<UploadCSVFileInfoProgressBar data-testid="new-order-csv-upload-progress-bar">
					<LinearProgress
						color={hasError ? "error" : "primary"}
						variant={isUpdating && !hasError && !isSuccess ? "indeterminate" : "determinate"}
						value={hasError ? 2 : 100}
					/>
					{!isUpdating && (
						<Close
							onClick={removeFile}
							data-testid="new-order-csv-upload-remove-file"
						/>
					)}
				</UploadCSVFileInfoProgressBar>
			)}
			{(isSuccess || hasError) && (
				<UploadModalCSVFileStatusText
					isSuccess={isSuccess}
					data-testid="new-order-csv-upload-status-text"
				>
					{isSuccess ? (
						<CheckCircleOutline data-testid="new-order-csv-upload-status-success-svg" />
					) : (
						<ErrorOutline data-testid="new-order-csv-upload-status-error-svg" />
					)}
					{uploadNotFound && (
						<UploadErrorWaringText data-testid="new-order-csv-upload-error-unsupported-file-text">
							{NewOrderImportSelectionDoesntMatch}
							<ul>{errors?.map((error: any) => <li key={error.message}>{error.message}</li>)}</ul>
						</UploadErrorWaringText>
					)}
					{renderUploadStatus(NewOrderImportFileError, uploadFileNull || uploadNoConfigurations)}
					{renderUploadStatus(NewOrderUnsupportedFile, uploadUnsupportedFile || fetchError)}
					{renderUploadStatus(NoAccountText, uploadUnauthorized)}
					{renderUploadStatus(NewOrderImportFileError, uploadGenericError)}
					{renderUploadStatus(FileSuccessText, isSuccess)}
				</UploadModalCSVFileStatusText>
			)}
		</UploadModalCSVFileInfoContainer>
	);
};

export default CSVFileUploadProgress;
