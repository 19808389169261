import { useMemo } from "react";
import {
	AccountBillToDialogFormContainer,
	AccountBillToDialogTitle,
	AccountInfoContainer,
	AllFieldsText,
	ErrorWrapper,
	HeaderTitleStyles,
	StartOverContinueContainer
} from "./NewOrders.styles";
import { Content } from "pages/Replacements/components/AddNewAddressModal/addNewAddressModal.styles.ts";
import {
	AllFieldsRequiredText,
	ContinueButtonText,
	ImportAddOnErrorText,
	SelectAccountAndBillingText,
	StartOverText
} from "constants/text";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import AccountBillToContent from "./AccountBillToContent";
import { getGroupedConfigurations, useCheckForBillToAccountErrors } from "components/NewOrders/utils/NewOrderUtils.tsx";
import { useLocation, useParams } from "react-router-dom";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { NewOrderModalTracking } from "data/api/v1";
import { NewOrderCancelButton } from "./constants.ts";
import { ImportOrderResponseData } from "types/api/orders/importOrder.ts";
import { models } from "types/api/viewModels.ts";
import ErrorBanner from "components/Replacements/ErrorBanner.tsx";
import AccountInfo from "components/NewOrders/components/AccountInfo/AccountInfo.tsx";

interface AccountBillToDialogFormProps {
	handleFindOriginalOrder: () => void;
	handleModalContinue: () => void;
	csvContents: ImportOrderResponseData[] | undefined;
	isAddOn?: boolean;
	orderDetails?: models["OrderDetailViewModel"];
}

const AccountBillToDialogForm = ({
	handleFindOriginalOrder,
	handleModalContinue,
	csvContents,
	isAddOn,
	orderDetails
}: AccountBillToDialogFormProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const params = useParams();
	const productLineAccountInfo = useSelector((state: RootState) => state.newOrder.productLineAccounts);

	const isValidForAddOn = useMemo(() => {
		if (!isAddOn) return true;
		const importedCSVProductLines: Set<string | null | undefined> = new Set(
			csvContents?.flatMap((csv) => csv?.configurations).map((config) => config?.globals?.productLine?.code) ?? []
		);
		const filteredProductLines = Object.entries(productLineAccountInfo).filter(([productLine]) =>
			importedCSVProductLines.has(productLine)
		);
		const billTosByProductLine = filteredProductLines.map(([, accounts]) =>
			accounts?.flatMap((account) => account?.billTos)
		);
		const isValidBillTo = billTosByProductLine.every((productLine) =>
			productLine?.find((billTo) => billTo?.billToId === orderDetails?.billToId)
		);
		const isValidAccount = filteredProductLines.every(([, accounts]) =>
			accounts?.find((account) => account.accountNumber === orderDetails?.accountNumber)
		);

		return isValidAccount && isValidBillTo;
	}, [csvContents, isAddOn, orderDetails?.accountNumber, orderDetails?.billToId, productLineAccountInfo]);

	const addOnAccount = useMemo(
		() =>
			Object.entries(productLineAccountInfo)
				.flatMap(([, accounts]) => accounts)
				.find((account) => account?.accountNumber === orderDetails?.accountNumber),
		[orderDetails?.accountNumber, productLineAccountInfo]
	);

	const addOnBillTo = useMemo(
		() => addOnAccount?.billTos?.find?.((billTo) => billTo.billToId === orderDetails?.billToId),
		[addOnAccount?.billTos, orderDetails?.billToId]
	);

	const checkForErrors = useCheckForBillToAccountErrors(handleModalContinue, csvContents);

	const handleCancel = () => {
		dispatch(newOrderActions.clearTempParsedCSV());
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
	};

	return (
		<AccountBillToDialogFormContainer>
			<HeaderTitleStyles>
				<AccountBillToDialogTitle>{SelectAccountAndBillingText}</AccountBillToDialogTitle>
				<AllFieldsText className="body2">{AllFieldsRequiredText}</AllFieldsText>
			</HeaderTitleStyles>
			{!isValidForAddOn && (
				<ErrorWrapper>
					<ErrorBanner
						errorMessage=""
						title={ImportAddOnErrorText}
					/>
				</ErrorWrapper>
			)}
			<Content>
				{csvContents &&
					Object.entries(getGroupedConfigurations(csvContents)).map(([productLine, CSVs]) => (
						<AccountBillToContent
							key={productLine}
							csvContents={CSVs}
							productLine={productLine}
							isAddOn={isAddOn}
							addOnAccount={addOnAccount}
							addOnBillTo={addOnBillTo}
						/>
					))}
				{isAddOn && (
					<AccountInfoContainer>
						<AccountInfo
							account={addOnAccount}
							orderDetails={orderDetails}
							variant="ACCOUNT"
						/>
						<AccountInfo
							billTo={addOnBillTo}
							orderDetails={orderDetails}
							variant="BILLTO"
						/>
					</AccountInfoContainer>
				)}
				<StartOverContinueContainer>
					{location.pathname !== "/new-order/build-order" &&
					location.pathname !== `/new-order/build-order/${params.draftId}` &&
					location.pathname !== `/add-ons/build-order/${params.orderId}` ? (
						<Button
							onClick={handleFindOriginalOrder}
							variant={isAddOn && !isValidForAddOn ? "contained" : "outlined"}
							fullWidth
						>
							{StartOverText}
						</Button>
					) : (
						<Button
							onClick={handleCancel}
							variant={isAddOn && !isValidForAddOn ? "contained" : "outlined"}
							fullWidth
						>
							{NewOrderCancelButton}
						</Button>
					)}
					{(isValidForAddOn || !isAddOn) && (
						<Button
							onClick={checkForErrors}
							variant="contained"
							fullWidth
						>
							{ContinueButtonText}
						</Button>
					)}
				</StartOverContinueContainer>
			</Content>
		</AccountBillToDialogFormContainer>
	);
};

export default AccountBillToDialogForm;
