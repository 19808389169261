import { LineItemBackground, LineItemErrorMessage, LineItemGridContainer, ModContainer } from "./LineItemGridStyles";
import { FC, ReactNode } from "react";
import LineItemSkeleton from "./LineItemSkeleton";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

interface LineItemGridProps {
	mainRowContent: ReactNode[];
	isLoading?: boolean;
	className?: string;
	modificationRows?: ReactNode[];
	dimensionRows?: ReactNode[];
	requiredCategoriesRows?: ReactNode[];
	error?: string | null | undefined;
	index?: number;
}
const LineItemGrid: FC<LineItemGridProps> = ({
	className,
	isLoading = false,
	mainRowContent,
	modificationRows,
	dimensionRows,
	requiredCategoriesRows,
	error,
	index = 0
}) => {
	const hasModificationRows = modificationRows && modificationRows.length > 0;
	const hasDimensionRows = dimensionRows && dimensionRows.length > 0;
	const hasRequiredCategoriesRows = requiredCategoriesRows && requiredCategoriesRows.length > 0;

	return (
		<div>
			<LineItemSkeleton isLoading={isLoading} />
			<LineItemBackground
				isLoading={isLoading}
				variant="outlined"
			>
				{error && error[index] && (
					<LineItemErrorMessage data-testid="line-item-error-message">
						<ErrorOutline />
						<span>{error}</span>
					</LineItemErrorMessage>
				)}
				<LineItemGridContainer
					className={className}
					mainContentColumnCount={mainRowContent.length}
				>
					<div>
						{mainRowContent}
						{hasModificationRows && (
							<ModContainer row={2}>
								{modificationRows}
								{!hasDimensionRows && requiredCategoriesRows}
							</ModContainer>
						)}
						{hasDimensionRows && (
							<ModContainer row={hasModificationRows ? 3 : 2}>
								{dimensionRows}
								{requiredCategoriesRows}
							</ModContainer>
						)}
						{requiredCategoriesRows && !hasModificationRows && !hasDimensionRows && (
							<ModContainer row={2}>{requiredCategoriesRows}</ModContainer>
						)}
					</div>
					{(hasModificationRows || hasDimensionRows || requiredCategoriesRows) && (
						<div>
							{hasModificationRows && modificationRows}
							{hasDimensionRows && dimensionRows}
							{hasRequiredCategoriesRows && requiredCategoriesRows}
						</div>
					)}
				</LineItemGridContainer>
			</LineItemBackground>
		</div>
	);
};

export default LineItemGrid;
