import { CharacterLimitExceeded, RequiredFieldNotice } from "constants/text";
import { NewOrdersDialogWrapper } from "../NewOrders.styles";
import { IsLoadingBody, SaveDraftModalBody, SaveDraftModalButtons, SaveDraftModalHeader } from "./SaveDraft.styles";
import {
	ComeBackToOrderText,
	ComeBackToTemplateText,
	DraftNamePlaceholder,
	InputDraftTitleError,
	InputTemplateTitleError,
	ReplacePreviouslySavedDraftText,
	ReplacePreviouslySavedTemplateText,
	SaveDraftHangTightText,
	SaveDraftSuccess,
	SaveDraftSuccessfullySavedText,
	SaveDraftText,
	SaveTemplateSuccessfullySavedText,
	SaveTemplateText,
	TemplateNamePlaceholder
} from "../constants";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Close from "@mui/icons-material/Close";
import type { DialogProps } from "@mui/material";
import { Button, LinearProgress, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSaveDraftOrderMutation } from "features/api/orderApi.ts";
import { useDispatch, useSelector } from "react-redux";
import HelperText from "components/Form/HelperText/HelperText";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { v4 as uuidv4 } from "uuid";
import { models } from "types/api/viewModels.ts";
import { decodeBase64WhenEncoded } from "utils/string";
import { RootState } from "stores/application.store.tsx";
import { OrderType } from "data/api/v1";

interface SaveDraftModalProps {
	saveDraftModalIsOpen: boolean;
	closeModal: () => void;
	handleSecondaryButton: () => void;
	firstButtonText: string;
	secondButtonText: string;
	draftOrder?: models["DraftOrderViewModel"];
	isTemplate?: boolean;
}

const DRAFT_TITLE_MAX_CHAR = 50;

const SaveDraftModal = ({
	saveDraftModalIsOpen,
	closeModal,
	handleSecondaryButton,
	firstButtonText,
	secondButtonText,
	draftOrder,
	isTemplate
}: SaveDraftModalProps) => {
	const [draftTitle, setDraftTitle] = useState(decodeBase64WhenEncoded(draftOrder?.draftName ?? ""));
	const [errorText, setErrorText] = useState<undefined | string>(undefined);
	const [draftTitleHasError, setDraftTitleHasError] = useState(false);
	const [saveDraftOrder, { isLoading, isSuccess, reset, isError, error: errorData }] = useSaveDraftOrderMutation();
	const dispatch = useDispatch();
	const orderType = useSelector((state: RootState) => state.newOrder.orderType);
	const [isSavedDraft, setIsSavedDraft] = useState(draftOrder?.draftName && draftOrder.draftName !== "");
	const handleCloseModal = useCallback(() => {
		setErrorText(undefined);
		setDraftTitleHasError(false);
		reset();
		closeModal();
	}, [closeModal, reset]);

	useEffect(() => {
		if (draftOrder?.draftName) {
			setDraftTitle(decodeBase64WhenEncoded(draftOrder.draftName));
		}
	}, [draftOrder?.draftName]);

	useEffect(() => {
		if (isSuccess) {
			setTimeout(() => {
				handleSecondaryButton();
				setTimeout(reset, 300);
			}, 4000);
		} else if (isError) {
			handleCloseModal();
			dispatch(submittingNewOrderActions.updateDraftError(errorData));
		}
	}, [isSuccess, isError, dispatch, draftTitle, handleCloseModal, handleSecondaryButton, errorData, reset]);

	const submitDraft = async () => {
		const draftOrderId = draftOrder?.draftName === draftTitle ? (draftOrder.draftOrderId ?? uuidv4()) : uuidv4();
		const draft: models["DraftOrderViewModel"] = {
			...draftOrder,
			draftOrderId: draftOrderId,
			draftName: draftTitle.trim(),
			orderType: orderType.toUpperCase() as OrderType,
			type: isTemplate ? "Template" : "Draft"
		};
		try {
			await saveDraftOrder(draft);
			dispatch(submittingNewOrderActions.updateDraftOrder(draft));
			setIsSavedDraft(true);
			dispatch(submittingNewOrderActions.clearDraftError());
		} catch (error: any) {
			console.error("error:", error);
			handleCloseModal();
			dispatch(submittingNewOrderActions.updateDraftError(error));
		}
	};

	const checkForErrors = () => {
		if (draftTitle.length <= DRAFT_TITLE_MAX_CHAR) {
			if (draftTitle.trim() === "") {
				setDraftTitleHasError(true);
				setErrorText(isTemplate ? InputTemplateTitleError : InputDraftTitleError);
			} else {
				submitDraft();
			}
		}
	};

	let content;
	if (isLoading) {
		content = (
			<IsLoadingBody>
				<h6>{SaveDraftHangTightText}</h6>
				<div>
					<span>{draftTitle}</span>
					<LinearProgress style={{ width: "100%" }} />
				</div>
			</IsLoadingBody>
		);
	} else if (isSuccess) {
		content = (
			<IsLoadingBody>
				<h6>{isTemplate ? SaveTemplateSuccessfullySavedText : SaveDraftSuccessfullySavedText}</h6>
				<div>
					<span>{draftTitle}</span>
					<LinearProgress
						variant="determinate"
						style={{ width: "100%" }}
						value={100}
					/>
					<div>
						<CheckCircleOutline />
						<span>{SaveDraftSuccess}</span>
					</div>
				</div>
			</IsLoadingBody>
		);
	} else {
		content = (
			<>
				<p>{RequiredFieldNotice}</p>
				<TextField
					label={isTemplate ? TemplateNamePlaceholder : DraftNamePlaceholder}
					value={draftTitle}
					data-testid="save-draft-input"
					onChange={(e) => {
						setDraftTitleHasError(false);
						setDraftTitle(e.target.value);
					}}
					sx={{ marginBottom: "4px" }}
				/>
				<HelperText
					isError={draftTitle.length > DRAFT_TITLE_MAX_CHAR || draftTitleHasError}
					text={
						draftTitleHasError
							? errorText
							: draftTitle.length > DRAFT_TITLE_MAX_CHAR
								? CharacterLimitExceeded
								: ""
					}
					characterLimit={{
						currentLength: draftTitle.length,
						limit: DRAFT_TITLE_MAX_CHAR,
						isLimitError: draftTitle.length > DRAFT_TITLE_MAX_CHAR
					}}
				/>
				<SaveDraftModalButtons>
					<Button
						variant="outlined"
						onClick={handleCloseModal}
					>
						{secondButtonText}
					</Button>
					<Button
						variant="contained"
						onClick={checkForErrors}
					>
						{firstButtonText}
					</Button>
				</SaveDraftModalButtons>
			</>
		);
	}

	const handleDialogClose: DialogProps["onClose"] = (event, reason) => {
		if (reason === "backdropClick" && (isSuccess || isLoading)) return;
		handleCloseModal();
	};

	let subheaderText: string;

	if (isSavedDraft) {
		if (isTemplate) {
			subheaderText = ReplacePreviouslySavedTemplateText;
		} else {
			subheaderText = ReplacePreviouslySavedDraftText;
		}
	} else {
		if (isTemplate) {
			subheaderText = ComeBackToTemplateText;
		} else {
			subheaderText = ComeBackToOrderText;
		}
	}

	return (
		<NewOrdersDialogWrapper
			open={saveDraftModalIsOpen}
			onClose={handleDialogClose}
		>
			<SaveDraftModalHeader>
				<div>
					<h6>{isTemplate ? SaveTemplateText : SaveDraftText}</h6>
					{!isLoading && !isSuccess && <p>{subheaderText}</p>}
				</div>
				{!isLoading && !isSuccess && <Close onClick={handleCloseModal} />}
			</SaveDraftModalHeader>
			<SaveDraftModalBody isError={draftTitleHasError || draftTitle.length > DRAFT_TITLE_MAX_CHAR}>
				{content}
			</SaveDraftModalBody>
		</NewOrdersDialogWrapper>
	);
};

export default SaveDraftModal;
