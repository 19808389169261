import styled from "@emotion/styled";

interface StyledFormHelperTextProps {
	isLimitError: boolean | undefined;
	hasBackground?: boolean;
}

export const StyledFormHelperText = styled.span<StyledFormHelperTextProps>(({ isLimitError, theme }) => ({
	alignItems: "center",
	display: "flex",
	fontSize: "0.75rem",
	fontWeight: "500",
	justifyContent: "space-between",
	lineHeight: "var(--line-height-6)",
	svg: {
		height: 20,
		marginRight: 8,
		width: 20
	},
	"& > span": {
		alignItems: "center",
		display: "flex",
		minHeight: 20,
		"&:nth-of-type(2)": {
			color: isLimitError ? theme.palette.error.main : theme.project.colors.fontColors.secondary
		}
	}
}));

export const StyledSearchOrderLimitHelperText = styled(StyledFormHelperText)(({ theme, hasBackground }) => ({
	fontSize: 16,
	color: theme.project.colors.allColors.hex.blue.cwg.cwgBlue900,
	display: "flex",
	border: hasBackground ? "1px solid " + theme.project.colors.allColors.hex.grayscale.gray300 : "none",
	padding: hasBackground ? "14px 8px" : "6px 0",
	alignItems: "flex-start",
	justifyContent: "flex-start",
	marginTop: hasBackground ? 16 : 0,
	background: hasBackground ? theme.project.colors.backgroundColors.main : "none",
	svg: {
		width: 22,
		height: 22,
		marginRight: 12
	},
	[theme.breakpoints.down("lg")]: {
		marginTop: 16,
		padding: hasBackground ? "14px 8px" : 0
	}
}));
