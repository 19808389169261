import { AddOnAccountBillTo, LineItemAccordionDetails } from "components/Common/LineItemGrid/LineItemGridStyles";
import { Fragment, MutableRefObject, useEffect } from "react";
import { models } from "types/api/viewModels";
import ReviewOrderAccountBillTo from "../ReviewOrderAccountBillTo/ReviewOrderAccountBillTo";
import { BuildNewOrderLineItemsHeader, UploadErrorWaringText, UploadErrorWarning } from "../NewOrders.styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
	GlobalAttributeEmptyError,
	NewOrderBuildGridDoorStyleColumnHeader,
	NewOrderBuildGridFinishColumnHeader,
	NewOrderBuildGridQuantityColumnHeader,
	NewOrderEdit,
	NewOrderRemoveButton,
	NewOrderRemoveGroup
} from "../constants";
import { getAttributes, getConfigDisplay, getSubheaderAttributes } from "../utils/NewOrderUtils";
import { AddItemButtonWrapper, BuildLineItemGrid, ConfigAttributeTools } from "./buildNewOrderLineItems.styles";
import GlobalFormProvider from "../AddGlobalAttributes/forms/GlobalFormProvider";
import AddGlobalAttributes from "../AddGlobalAttributes/AddGlobalAttributes";
import { Button, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditLineItem from "../EditLineItem/EditLineItem";
import Header from "../../Common/LineItemGrid/Header/Header.tsx";
import { LinkButton } from "components/Common/Link";
import { Modification } from "components/Common/LineItemGrid";
import { AddItemButton } from "../AddLineItem/AddLineItemStyles";
import AddIcon from "@mui/icons-material/Add";
import { AddLineAddLineButtonText } from "../AddLineItem/constants";
import AddNewLineItem from "../AddLineItem/AddLineItem.tsx";
import { FileContentProps } from "./FileContent.tsx";
import { ImportOrderResponseData } from "types/api/orders/importOrder.ts";

interface ConfigurationContentProps extends FileContentProps {
	configuration: models["PendingOrderConfigurationViewModel"] | models["ValidatedOrderConfigurationViewModel"];
	setConfigurationToUpdate: (configuration: models["PendingOrderConfigurationViewModel"]) => void;
	configIndex: number;
	showEditLineItem: number | undefined;
	editLineItemIndex: number | undefined;
	toggleEditItem: (index: number) => void;
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
	handleEditLineItem: (item: models["PendingLineItemViewModel"], itemIndex: number, index: number) => void;
	showAddItemButton: [number, number] | undefined;
	toggleAddItem: (
		groupIndex: number | undefined,
		configIndex: number | undefined,
		shouldShowEstimateButton: boolean
	) => void;
	parsedCSV: ImportOrderResponseData[] | undefined;
}

const ConfigurationContent = ({
	configuration,
	isAddOn,
	isLoading,
	postError,
	setConfigurationToUpdate,
	handleConfigurationUpdate,
	configIndex,
	handleRemoveGroup,
	searchValue,
	showEditLineItem,
	editLineItemIndex,
	group,
	toggleEditItem,
	selectedEditItem,
	handleMoveLineItem,
	lineItemErrors,
	handleEditLineItem,
	handleOpenRemoveModal,
	groupIndex,
	showAddItemButton,
	toggleAddItem,
	parsedCSV,
	handleLineItem,
	groupedDisplayConfigs
}: ConfigurationContentProps) => {
	useEffect(() => {
		const lastConfigIndex = group.configurations.length - 1;
		if (
			group.configurations[lastConfigIndex]?.configurationId === configuration.configurationId &&
			(!group.configurations[lastConfigIndex]?.lineItems ||
				group.configurations[lastConfigIndex]?.lineItems?.length === 0)
		)
			toggleAddItem(groupIndex, lastConfigIndex, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Fragment key={configuration.configurationId}>
			{isAddOn && !isLoading && (
				<AddOnAccountBillTo>
					<ReviewOrderAccountBillTo addOnConfig={configuration} />
				</AddOnAccountBillTo>
			)}
			<LineItemAccordionDetails>
				{postError && configuration.lineItems?.length === 0 && (
					<UploadErrorWarning>
						<ErrorOutlineIcon aria-label="add-line-items" />
						<UploadErrorWaringText data-testid="empty-attribute-group">
							{GlobalAttributeEmptyError}
						</UploadErrorWaringText>
					</UploadErrorWarning>
				)}
				<BuildNewOrderLineItemsHeader>
					<div data-testid="new-order-build-label">
						<div>{getAttributes(configuration)}</div>
						<div>{getSubheaderAttributes(configuration)}</div>
					</div>
					<ConfigAttributeTools>
						<GlobalFormProvider isUpdate>
							<AddGlobalAttributes
								setConfigurationToUpdate={() => setConfigurationToUpdate(configuration)}
								handleConfigurationUpdate={(config) => handleConfigurationUpdate(config, configIndex)}
								isUpdate
							/>
						</GlobalFormProvider>
						<Button
							onClick={() => handleRemoveGroup(configuration, configIndex)}
							variant="text"
							data-testid="remove-group"
							sx={{ gap: "8px" }}
							data-id={`${isAddOn ? "add-ons" : "new-order"}-build-order-remove-global-attributes-button`}
						>
							<DeleteIcon />
							{NewOrderRemoveGroup}
						</Button>
					</ConfigAttributeTools>
				</BuildNewOrderLineItemsHeader>
				{configuration?.lineItems?.map((item, itemIndex) => {
					const foundSearch = `${item.sku} - ${item.description}`
						.toUpperCase()
						.includes((searchValue as string).toUpperCase());
					// Check if this is the item being edited
					const isEditing = configIndex === showEditLineItem && itemIndex === editLineItemIndex;
					if (foundSearch) {
						if (isEditing) {
							// Render EditLineItem instead of labels
							return (
								<EditLineItem
									key={`${item.sku}-${item.lineItemNumber}-edit`}
									group={group}
									configuration={configuration}
									addEditVisibility={toggleEditItem}
									selectedEditItem={selectedEditItem}
									description={configuration?.globals?.productLine?.description}
									editLineItemIndex={editLineItemIndex}
									handleMoveLineItem={handleMoveLineItem}
									isAddLineItem={false}
								/>
							);
						} else {
							// Render the regular view with labels
							return (
								<BuildLineItemGrid
									isLoading={isLoading}
									key={`${item.sku}-${item.lineItemNumber}`}
									index={itemIndex}
									error={lineItemErrors[Number(item.lineItemNumber) - 1]}
									mainRowContent={[
										<Header
											description={item?.description ?? ""}
											key={`${item.sku}-${item.lineItemNumber}-header`}
											header={item?.sku ?? ""}
											lineNumber={item?.lineItemNumber ?? ""}
										/>,
										<div key={`${item.sku}-${item.lineItemNumber}-empty`} />,
										<div key={`${item.sku}-${item.lineItemNumber}-door-style`}>
											<div>{NewOrderBuildGridDoorStyleColumnHeader}</div>
											<div data-testid="line-item-grid-data-door-style">
												{configuration.globals?.style?.description}
											</div>
										</div>,
										<div key={`${item.sku}-${item.lineItemNumber}-finish`}>
											<div>{NewOrderBuildGridFinishColumnHeader}</div>
											<div data-testid="line-item-grid-data-finish">
												{configuration.globals?.finish?.description}
											</div>
										</div>,
										<div key={`${item.sku}-${item.lineItemNumber}-qty`}>
											<div>{NewOrderBuildGridQuantityColumnHeader}</div>
											<div data-testid="line-item-grid-data-quantity">{item.quantityOrdered}</div>
										</div>,
										<div key={`${item.sku}-${item.lineItemNumber}-remove`}>
											<LinkButton
												onClick={() => {
													handleEditLineItem(item, itemIndex, configIndex);
												}}
												data-testid="line-item-edit-link"
												data-id={`${isAddOn ? "add-ons" : "new-order"}-build-order-edit-item-button`}
											>
												{NewOrderEdit}
											</LinkButton>

											<LinkButton
												variant="remove"
												onClick={() => handleOpenRemoveModal(item, itemIndex, configIndex)}
												data-testid="line-item-remove-link"
												data-id={`${isAddOn ? "add-ons" : "new-order"}-build-order-remove-item-button`}
											>
												{NewOrderRemoveButton}
											</LinkButton>
										</div>
									]}
									modificationRows={item.modifications?.map((modification) => (
										<Modification
											description={modification.description}
											key={modification.sku}
											lineNumber={modification.lineItemNumber}
											title={modification.sku}
											values={modification.values}
										/>
									))}
									dimensionRows={item.requiredDimensions?.map((dimension, index: number) => (
										<Modification
											dimensionDescription={dimension.description}
											key={dimension.description}
											dimensionValue={dimension.value}
											lineNumber=""
										/>
									))}
									requiredCategoriesRows={item.requiredCategories?.map((category) => (
										<Modification
											key={category.categoryName}
											requiredCategory={category}
											lineNumber=""
										/>
									))}
								/>
							);
						}
					} else {
						return <></>;
					}
				})}
			</LineItemAccordionDetails>
			{(groupIndex !== showAddItemButton?.[0] || configIndex !== showAddItemButton?.[1]) &&
			showEditLineItem === undefined ? (
				<>
					<AddItemButton
						variant="text"
						onClick={() => toggleAddItem(groupIndex, configIndex, false)}
						data-testid="add-item-button"
						data-id={`${isAddOn ? "add-ons" : "new-order"}-build-order-add-item-button`}
					>
						<AddIcon />
						{AddLineAddLineButtonText}
					</AddItemButton>
					<AddItemButtonWrapper>{getConfigDisplay(configuration, parsedCSV)}</AddItemButtonWrapper>
				</>
			) : (
				(groupIndex === showAddItemButton?.[0] || configIndex === showAddItemButton?.[1]) &&
				showEditLineItem === undefined && (
					<>
						<AddNewLineItem
							selectedEditItem={selectedEditItem}
							handleLineItem={handleLineItem}
							configuration={configuration}
							addItemVisibility={() => toggleAddItem(undefined, undefined, true)}
							isAddLineItem
						/>
						<AddItemButtonWrapper>{getConfigDisplay(configuration, parsedCSV)}</AddItemButtonWrapper>
					</>
				)
			)}
			{configIndex !== (groupedDisplayConfigs?.[groupIndex]?.configurations?.length ?? 0) - 1 && (
				<Divider sx={{ margin: "16px", padding: 0 }} />
			)}
		</Fragment>
	);
};

export default ConfigurationContent;
