import { Box } from "@mui/material";
import { SaveDraftNoDraftsFilterWrapper } from "./SaveDraftNoDraftsFilterWrapper.styles";

const SaveDraftNoDraftsFilter = ({ SaveDraftTemplateModalDetails }: any) => {
	return (
		<SaveDraftNoDraftsFilterWrapper>
			<Box>
				<img
					src="/assets/NoOrdersSadBoxImage.svg"
					alt="No Orders Banner"
				/>
				<h6 data-testid="no-save-draft-orders-filter-header">
					{SaveDraftTemplateModalDetails.NoSaveFoundHeader}
				</h6>
				<div data-testid="no-save-draft-orders-filter-subheader">
					{SaveDraftTemplateModalDetails.NoSaveListSubheader}
				</div>
				<ul>
					<li>{SaveDraftTemplateModalDetails.NoSaveFoundFirstBullet}</li>
					<li>{SaveDraftTemplateModalDetails.NoSaveFoundSecondBullet}</li>
					<li>{SaveDraftTemplateModalDetails.NoSaveFoundThirdBullet}</li>
				</ul>
			</Box>
			<div data-testid="no-save--orders-filter-footer">{SaveDraftTemplateModalDetails.NoSaveListFooter}</div>
		</SaveDraftNoDraftsFilterWrapper>
	);
};

export default SaveDraftNoDraftsFilter;
