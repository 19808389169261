import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { UserDetailsResponseData } from "types/api/users/getUserDetails.ts";

interface ImpersonationSlice {
	impersonationUser: string | undefined;
	isCareUser: boolean;
	originalUserData: UserDetailsResponseData;
}

const impersonationData: ImpersonationSlice | undefined = read("_impersonation");

export const initialState: ImpersonationSlice = impersonationData || {
	impersonationUser: undefined,
	isCareUser: false,
	originalUserData: undefined
};

const impersonationSlice = createSlice({
	name: "impersonation",
	initialState,
	reducers: {
		setImpersonationUser: (state, action: PayloadAction<string | undefined>) => {
			state.impersonationUser = action.payload;
		},
		setIsCareUser: (state, action: PayloadAction<boolean>) => {
			state.isCareUser = action.payload;
		},
		setOriginalUserDetails: (state, action: PayloadAction<UserDetailsResponseData>) => {
			state.originalUserData = { ...action.payload };
		}
	}
});

export const impersonationActions = impersonationSlice.actions;
export default impersonationSlice.reducer;
