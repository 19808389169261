import styled from "@emotion/styled";
import { SUBGRID_TEMPLATE } from "components/Common/LineItemGrid/LineItemGridStyles";

interface WrapperProps {
	rowCount: number;
}

export const Wrapper = styled.div<WrapperProps>(({ theme, rowCount }) => ({
	...SUBGRID_TEMPLATE,
	fontSize: "0.875rem",
	"& > div:first-of-type": {
		display: "flex",
		alignItems: "baseline",
		gap: 12,
		gridColumn: "span 8", // Modification name column,
		[theme.breakpoints.up("md")]: {
			alignItems: "center"
		}
	},
	"& > div:nth-of-type(2)": {
		...SUBGRID_TEMPLATE,
		gridColumn: "span 2"
	},
	"& > div > div:first-of-type": {
		minWidth: 26,
		height: 16
	},
	"& > div > div:nth-of-type(2)": {
		display: "flex",
		flexWrap: "wrap",
		gap: 8,
		"div:nth-of-type(1)": {
			color: theme.project.colors.fontColors.main,
			fontWeight: 500,
			whiteSpace: "pre-wrap",
			span: {
				...theme.project.typography.body.body2,
				lineHeight: "normal",
				marginLeft: 12
			}
		}
	},
	[theme.breakpoints.down("md")]: {
		gap: 12,
		gridTemplateColumns: "minmax(42px, min-content) auto",
		gridTemplateRows: `repeat(${rowCount}, 1fr)`,
		"& > div:first-of-type": {
			...SUBGRID_TEMPLATE
		},
		"&&&& > div > div:first-of-type": {
			color: theme.project.colors.fontColors.secondary,
			fontWeight: 400,
			gridColumn: "1"
		},
		"&&& > div:nth-of-type(2)": {
			...(rowCount === 1 && { display: "none" }),
			columnGap: 8,
			gridColumn: "2",
			gridRow: "2",
			gridTemplateColumns: "min-content min-content",
			"div:nth-of-type(1)": {
				fontWeight: 400
			}
		}
	}
}));
