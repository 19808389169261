import { type FC, type SyntheticEvent, useEffect, useState } from "react";
import { EmulationForm, StyledDialog } from "components/Impersonation/impersonation.styles.ts";
import { useGetUserEmulationQuery } from "features/api/userApi.ts";
import { impersonationActions } from "features/reducers/settings/impersonation.ts";
import { DialogTitle, FooterActions } from "components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "components/Common/Autocomplete/Autocomplete.tsx";
import type { RootState } from "stores/application.store.tsx";
import {
	UserEmulationBackButton,
	UserEmulationConfirmButton,
	UserEmulationEmailLabel,
	UserEmulationFormErrorText,
	UserEmulationModalTitle
} from "constants/text.ts";

interface ModalProps {
	handleModalState(isOpen: boolean): void;
	handleSetReloadKey(): void;
	open: boolean;
}

const Modal: FC<ModalProps> = ({ handleModalState, handleSetReloadKey, open }) => {
	const { data: availableUsers, isFetching } = useGetUserEmulationQuery();
	const impersonationUser = useSelector((state: RootState) => state.impersonation.impersonationUser);
	const dispatch = useDispatch();

	// Separate state so we can store null for an error state
	const [selectedUser, setSelectedUser] = useState<string | null | undefined>();

	useEffect(() => {
		if (!impersonationUser) {
			setSelectedUser(undefined);
		}
	}, [impersonationUser]);

	const handleSubmit = () => {
		if (!selectedUser) {
			setSelectedUser(null);
		} else {
			dispatch(impersonationActions.setImpersonationUser(selectedUser));
			handleModalState(false);
		}
	};

	const handleChange = (_event: SyntheticEvent, value: string) => {
		setSelectedUser(value);
	};

	const handleModalClose = () => {
		setSelectedUser(selectedUser === null ? undefined : impersonationUser);
		handleModalState(false);
	};

	const handleBackToCareClick = () => {
		dispatch(impersonationActions.setImpersonationUser(undefined));
		setSelectedUser(undefined);
		handleSetReloadKey();
		handleModalState(false);
	};

	return (
		<StyledDialog
			open={open}
			data-testid="user-impersonation-modal"
		>
			<DialogTitle
				title={UserEmulationModalTitle}
				handleClose={handleModalClose}
			/>
			<EmulationForm>
				<AutoComplete
					disableClearable
					isLoading={isFetching}
					options={availableUsers ?? []}
					onChange={handleChange}
					errorText={UserEmulationFormErrorText}
					isError={selectedUser === null}
					value={selectedUser}
					virtualize
					required
					label={UserEmulationEmailLabel}
					dataTestId="user-impersonation-autocomplete"
					isOptionEqualToValue={(option: string, value: string) => option === value}
				/>
				<FooterActions
					cancelText={UserEmulationBackButton}
					confirmText={UserEmulationConfirmButton}
					handleClose={handleBackToCareClick}
					handleConfirm={handleSubmit}
				/>
			</EmulationForm>
		</StyledDialog>
	);
};

export default Modal;
