import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";
import { baseQueryWithRedirect } from "features/api/utils/apiUtils.ts";
import { models } from "types/api/viewModels.ts";
import {
	GetProductLinesQueryParameters,
	GetProductLinesResponse,
	GetProductLinesResponseData
} from "types/api/globals/getProductLines.ts";
import {
	GetProductLineByIdPathParameter,
	GetProductLineByIdResponse,
	GetProductLineByIdResponseData
} from "types/api/globals/getProductLineById.ts";

export const globalsApiSlice = createApi({
	reducerPath: "globalsApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getProductLines: builder.query<GetProductLinesResponseData, GetProductLinesQueryParameters>({
			query: (params) => endpoints.globals.getProductLines(params),
			transformResponse: (response: GetProductLinesResponse) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		getProductLine: builder.query<GetProductLineByIdResponseData, GetProductLineByIdPathParameter["productLineId"]>(
			{
				query: (productLineId) => endpoints.globals.getProductLine(productLineId),
				transformResponse: (response: GetProductLineByIdResponse) => response?.data,
				transformErrorResponse: (error: { status: string | number }) => ({
					status: error?.status
				})
			}
		),
		getStyleData: builder.query<models["StyleViewModel"] | undefined, string>({
			query: (styleId: string) => endpoints.globals.getStyle(styleId),
			transformResponse: (response: models["StyleViewModelApiResponse"]) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		getFinishData: builder.query<models["FinishViewModel"] | undefined, string>({
			query: (finishId: string) => endpoints.globals.getFinish(finishId),
			transformResponse: (response: models["FinishViewModelApiResponse"]) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		})
	})
});

export const {
	useGetProductLinesQuery,
	useLazyGetProductLineQuery,
	useLazyGetStyleDataQuery,
	useGetStyleDataQuery,
	useLazyGetFinishDataQuery,
	useGetFinishDataQuery
} = globalsApiSlice;
