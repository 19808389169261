import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const SaveDraftNoDraftsFilterWrapper = styled(Box)(({ theme }) => ({
	...theme.project.typography.body.body1,
	boxShadow: "0 0 1px 1px var(--gray-400)",
	padding: "48px 0",
	gap: "12px",
	textAlign: "center",
	display: "flex",
	width: "auto",
	flexDirection: "column",
	alignItems: "center",
	color: theme.project.colors.allColors.hex.grayscale.gray750,
	h6: {
		margin: "0 0 4px 0",
		lineHeight: "32px"
	},
	div: {
		"&:first-child": {
			marginTop: "0",
			div: {
				marginTop: "0",
				textAlign: "left",
				paddingLeft: "55px"
			}
		},
		"&:nth-child(2)": {
			...theme.project.typography.caption,
			padding: "0px 16px"
		}
	},
	ul: {
		margin: "0 0 8px 0",
		textAlign: "left",
		paddingLeft: "78px"
	},
	img: {
		width: "218px",
		marginBottom: "16px"
	},
	[theme.breakpoints.down("sm")]: {
		h6: {
			padding: "0px 16px"
		},
		div: {
			"&:first-child": {
				div: {
					padding: "0px 16px",
					textAlign: "center"
				}
			}
		},
		ul: {
			textAlign: "center",
			listStylePosition: "inside",
			padding: "0px 16px"
		}
	}
}));
