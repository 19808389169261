import { FC, useMemo } from "react";
import { AccountInfoBox } from "components/NewOrders/components/AccountInfo/accountInfo.styles.ts";
import { models } from "types/api/viewModels.ts";
import { AccountNumberText, BillToTitle } from "constants/text.ts";

interface AccountInfoProps {
	account?: models["CustomerAccountViewModel"] | null;
	billTo?: models["BillToViewModel"] | null;
	orderDetails?: models["OrderDetailViewModel"] | null | undefined;
	variant: "ACCOUNT" | "BILLTO";
}

const logisticsModeMap = {
	DIRECT: "Direct",
	CROSSDOCK: "Crossdock"
} as const;

const AccountInfo: FC<AccountInfoProps> = ({ account, billTo, orderDetails, variant }) => {
	const billToAddress = [billTo?.city, billTo?.state]?.filter(Boolean)?.join(", ");
	const billToId = variant === "BILLTO" ? (billTo?.billToId ?? orderDetails?.billToId) : undefined;
	const logisticsModeText = billTo?.logisticsMode ? logisticsModeMap[billTo.logisticsMode] : undefined;
	const billToText = orderDetails?.billTo ? `${orderDetails?.billTo} - ${billToId}` : billToId;

	const line1 = useMemo(() => {
		if (variant === "ACCOUNT") {
			return account?.accountNumber ?? orderDetails?.accountNumber;
		} else {
			if (!billTo) return billToText;

			return billTo?.projectName ?? billToAddress;
		}
	}, [account?.accountNumber, billTo, billToAddress, billToText, orderDetails?.accountNumber, variant]);

	const line2 = useMemo(() => {
		if (variant === "ACCOUNT") {
			return account?.accountName;
		} else {
			if (billTo?.projectName) {
				return [billToAddress, logisticsModeText, billToText].filter(Boolean).join(" - ");
			} else if (billTo) {
				return billToText;
			}
		}
	}, [account?.accountName, billTo, billToAddress, billToText, logisticsModeText, variant]);

	return (
		<AccountInfoBox data-testid={`readOnlyInfoBox-${variant}`}>
			<div>{variant === "ACCOUNT" ? AccountNumberText : BillToTitle}</div>
			<div>
				<div>
					{line1} {line2 && "-"}
				</div>
				<div>{line2}</div>
			</div>
		</AccountInfoBox>
	);
};

export default AccountInfo;
