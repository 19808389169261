import { AutoCompleteContainer, SelectionDetails } from "../addGlobalAttributes.styles";
import { Controller, useFormContext } from "react-hook-form";
import AutoComplete from "../../../Common/Autocomplete/Autocomplete";
import { GlobalAttributeFields } from "../forms/schema";
import {
	SelectAccentApplication,
	SelectAccentColor,
	SelectConstruction,
	SelectDistressing,
	SelectPackaging
} from "../../constants";
import { useLazyGetFinishDataQuery } from "features/api/globalsApi.ts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useEffect } from "react";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { models } from "types/api/viewModels.ts";
import { addNAOption, sortGlobalByDescription } from "components/NewOrders/utils/NewOrderUtils.tsx";

interface StepThreeProps {
	isUpdate?: boolean;
}

const StepThree = ({ isUpdate }: StepThreeProps) => {
	const { control, watch, clearErrors, setValue } = useFormContext<GlobalAttributeFields>();
	const selectedProductLine = watch("productLine");
	const selectedDoorStyle = watch("doorStyle");
	const selectedShape = watch("shape");
	const selectedSpecies = watch("species");
	const selectedFinish = watch("finish");
	const selectedHingeType = watch("hinge");
	const selectedCaseColor = watch("caseColor");
	const selectedProfile = watch("profile");
	const selectedAccentColor = watch("accentColor");
	const selectedFinishId = selectedFinish?.id;
	const dispatch = useDispatch();
	const updatedGlobalAttributes = useSelector((state: RootState) => state.newOrder.updatedGlobalAttributes);

	const [trigger, { data: finishData, isLoading }] = useLazyGetFinishDataQuery();

	// Fetch the finish only once for the lifecycle

	useEffect(() => {
		if (selectedFinishId) {
			trigger(selectedFinishId);
		}
	}, [selectedFinishId, trigger]);

	useEffect(() => {
		if (!isUpdate) {
			return;
		}

		const distressingValue = finishData?.distressings?.find(
			(distressing) => distressing.id === updatedGlobalAttributes?.globals?.distressing?.id
		);

		if (distressingValue) {
			setValue("distressing", distressingValue);
		}

		const accentApplicationValue = finishData?.accentApplications?.find(
			(accentApplication) => accentApplication.id === updatedGlobalAttributes?.globals?.accentApplication?.id
		);

		if (accentApplicationValue) {
			setValue("accentApplication", accentApplicationValue);
		}

		const accentColorValue = finishData?.accentColors?.find(
			(accentColor) => accentColor.id === updatedGlobalAttributes?.globals?.accentColor?.id
		);

		if (accentColorValue) {
			setValue("accentColor", accentColorValue);
		}

		const constructionValue = finishData?.constructions?.find(
			(construction) => construction.id === updatedGlobalAttributes?.globals?.construction?.id
		);

		if (constructionValue) {
			setValue("construction", constructionValue);
		}

		const packagingValue = finishData?.packagings?.find(
			(packaging) => packaging.id === updatedGlobalAttributes?.globals?.packaging?.id
		);

		if (packagingValue) {
			setValue("packaging", packagingValue);
		}
	}, [
		finishData?.constructions,
		finishData?.packagings,
		finishData?.distressings,
		finishData?.accentApplications,
		finishData?.accentColors,
		isUpdate,
		setValue,
		updatedGlobalAttributes?.globals?.construction?.id,
		updatedGlobalAttributes?.globals?.packaging?.id,
		updatedGlobalAttributes?.globals?.distressing?.id,
		updatedGlobalAttributes?.globals?.accentApplication?.id,
		updatedGlobalAttributes?.globals?.accentColor?.id
	]);

	const distressingOptions = finishData?.distressings ?? [];
	const accentColorOptions = finishData?.accentColors ?? [];
	const accentApplicationOptions = finishData?.accentApplications ?? [];
	const constructionOptions = finishData?.constructions ?? [];
	const packagingOptions = finishData?.packagings ?? [];

	const descriptions = [
		selectedProductLine?.description ?? updatedGlobalAttributes?.globals?.productLine?.description,
		selectedDoorStyle?.description,
		selectedShape?.description,
		selectedProfile?.description,
		selectedHingeType?.description,
		selectedCaseColor?.description,
		selectedSpecies?.description,
		selectedFinish?.description
	]
		.filter(Boolean)
		.join(", ");

	return (
		<AutoCompleteContainer>
			<SelectionDetails data-testid="global-attribute-modal-selection">
				<span>{descriptions}</span>
			</SelectionDetails>
			{distressingOptions.length !== 0 && (
				<Controller
					name="distressing"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectDistressing}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										distressing: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("distressing");
								onChange(value);
							}}
							options={addNAOption(distressingOptions)}
							dataTestId="select-distressing"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{accentColorOptions.length !== 0 && (
				<Controller
					name="accentColor"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectAccentColor}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										accentColor: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("accentColor");
								onChange(value);
							}}
							options={addNAOption(accentColorOptions)}
							dataTestId="select-accent-color"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{accentApplicationOptions.length !== 0 && (
				<Controller
					name="accentApplication"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disabled={!selectedAccentColor}
							label={SelectAccentApplication}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										accentApplication: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("accentApplication");
								onChange(value);
							}}
							options={addNAOption(accentApplicationOptions)}
							dataTestId="select-accent-application"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{constructionOptions.length !== 0 && (
				<Controller
					name="construction"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectConstruction}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										construction: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("construction");
								onChange(value);
							}}
							options={sortGlobalByDescription(constructionOptions)}
							dataTestId="select-construction"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
			{packagingOptions.length !== 0 && (
				<Controller
					name="packaging"
					control={control}
					render={({ field: { onChange, value = null }, fieldState: { error } }) => (
						<AutoComplete
							required
							isLoading={isLoading}
							value={value}
							disableClearable
							label={SelectPackaging}
							getOptionLabel={(option) => option?.description}
							onChange={(_event, value) => {
								if (isUpdate) {
									const globalAttributesToBeUpdated: models["GlobalsViewModel"] = {
										...updatedGlobalAttributes?.globals,
										packaging: {
											code: value.code,
											description: value.description,
											id: value.id
										}
									};
									dispatch(
										newOrderActions.updateGlobalAttributes({
											...updatedGlobalAttributes,
											globals: globalAttributesToBeUpdated
										})
									);
								}
								clearErrors("packaging");
								onChange(value);
							}}
							options={sortGlobalByDescription(packagingOptions)}
							dataTestId="select-packaging"
							isError={!!error}
							errorText={error?.message}
						/>
					)}
				/>
			)}
		</AutoCompleteContainer>
	);
};

export default StepThree;
