import { NewOrderModalTracking, OrderType } from "data/api/v1";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { ShipToViewModel } from "data/api/v1/model/ship-to-view-model.ts";
import { UserNavigationItemViewModel } from "data/api/v1/model/user-navigation-item-view-model.ts";
import { ImportOrderResponseData } from "types/api/orders/importOrder";
import { ValidateProductsResponseData } from "types/api/products/postValidate";
import { models } from "types/api/viewModels.ts";
import { GetAccountsResponseData } from "types/api/accounts/getAccounts.ts";
import { BillTosShipTosResponseData } from "types/api/accounts/billTosShipTos.ts";

export interface LinkNavigation {
	navItem?: UserNavigationItemViewModel;
	index?: number;
	state: "proceed" | "pause";
}

const newOrder = read("_newOrder");

export interface NewOrderSlice {
	leaveNewOrderFlow: LinkNavigation | undefined;
	modalStatus: NewOrderModalTracking;
	newAddresses: ShipToViewModel[];
	parsedCSV: ImportOrderResponseData[] | undefined;
	parsedTempCSV: ImportOrderResponseData[] | undefined;
	validatedOrderResponse: ValidateProductsResponseData | undefined;
	newGlobalAttributes: models["PendingOrderConfigurationViewModel"] | undefined;
	updatedGlobalAttributes: models["PendingOrderConfigurationViewModel"] | undefined;
	productLineAccounts: Record<string, GetAccountsResponseData>;
	orderType: OrderType;
}

export const initialState: NewOrderSlice = newOrder || {
	leaveNewOrderFlow: undefined,
	modalStatus: NewOrderModalTracking.NONE,
	newAddresses: [],
	parsedCSV: undefined,
	parsedTempCSV: undefined,
	validatedOrderResponse: undefined,
	newGlobalAttribute: undefined,
	updatedGlobalAttributes: undefined,
	productLineAccounts: {},
	orderType: OrderType.ORIGINAL
};

const newOrderSlice = createSlice({
	name: "newOrder",
	initialState,
	reducers: {
		addNewAddress: (state, action) => {
			const addressMatchCallback = (shipTo: ShipToViewModel | null) => {
				return (
					shipTo?.address?.line1 === action.payload?.line1 &&
					shipTo?.address?.line2 === action.payload?.line2 &&
					shipTo?.address?.line3 === action.payload?.line3 &&
					shipTo?.address?.city === action.payload?.city &&
					shipTo?.address?.zip === action.payload?.zip
				);
			};

			if (action.payload && !state.newAddresses?.some(addressMatchCallback)) {
				state.newAddresses?.push(action.payload);
			}

			return state;
		},
		updateOrderType: (state, action) => {
			state.orderType = action.payload;
		},
		setModalState: (state, action) => {
			state.modalStatus = action.payload;
		},
		addParsedCSV: (state, action: PayloadAction<ImportOrderResponseData>) => {
			state.parsedCSV = [...(state.parsedCSV ?? []), action.payload];
		},
		overwriteParsedCSV: (state, action: PayloadAction<ImportOrderResponseData[] | undefined>) => {
			state.parsedCSV = action.payload;
		},
		addTempParsedCSV: (
			state,
			action: PayloadAction<{
				importedCSV: ImportOrderResponseData;
				existingConfigs: models["PendingOrderConfigurationViewModel"][];
			}>
		) => {
			const importedCSVData = { ...action.payload.importedCSV };

			const recreatedCSVData: ImportOrderResponseData[] = Array.from(
				action.payload.existingConfigs
					?.reduce((accum: Map<number, ImportOrderResponseData>, current) => {
						const findCSVCb = (csv: ImportOrderResponseData) =>
							csv?.configurations?.find((config) => config.configurationId === current.configurationId);
						const existingStateCSVIndex = state.parsedCSV?.findIndex(findCSVCb) ?? -1;
						const existingStateCSV = state.parsedCSV?.[existingStateCSVIndex];
						const accumCSV = accum.get(existingStateCSVIndex);

						if (!accumCSV) {
							if (existingStateCSVIndex === -1) {
								accum.set(existingStateCSVIndex, {
									configurations: [current]
								});
							} else {
								accum.set(existingStateCSVIndex, {
									...existingStateCSV,
									configurations: [current]
								});
							}
						} else {
							accum.set(existingStateCSVIndex, {
								...accumCSV,
								configurations: [...(accumCSV?.configurations ?? []), current]
							});
						}
						return accum;
					}, new Map())
					?.values() ?? []
			);

			const allExistingConfigs = recreatedCSVData?.flatMap((csv) => csv?.configurations);

			if (!state.parsedTempCSV) state.parsedTempCSV = recreatedCSVData;

			if (importedCSVData) {
				const importedCSVDataCodes = recreatedCSVData?.flatMap((e) =>
					e?.configurations?.map((e) => e?.globals?.productLine?.code)
				);
				importedCSVDataCodes?.forEach((code) => {
					// we want to find an existing config with a given product line code
					// and then apply the account and billing info to all configs with our product line
					const existingConfigInProductLine = allExistingConfigs?.find(
						(config) => config?.globals?.productLine?.code === code
					);
					// this needs to be only for configs in the product line
					const updatedConfigs = importedCSVData?.configurations?.map((config) => {
						if (config.globals?.productLine?.code === code) {
							return {
								...config,
								accountId: existingConfigInProductLine?.accountId,
								accountName: existingConfigInProductLine?.accountName,
								accountNumber: existingConfigInProductLine?.accountNumber,
								billToId: existingConfigInProductLine?.billToId
							};
						}
						return config;
					});
					importedCSVData.configurations = updatedConfigs;
				});

				state?.parsedTempCSV?.push(importedCSVData);
			}
		},
		clearTempParsedCSV: (state) => {
			state.parsedTempCSV = undefined;
		},
		addGlobalAttributes: (state, action: PayloadAction<models["PendingOrderConfigurationViewModel"]>) => {
			state.newGlobalAttributes = action.payload;
		},
		updateGlobalAttributes: (state, action: PayloadAction<models["PendingOrderConfigurationViewModel"]>) => {
			state.updatedGlobalAttributes = { ...state.updatedGlobalAttributes, ...action.payload };
		},
		clearUpdateGlobalAttributes: (state) => {
			state.updatedGlobalAttributes = undefined;
		},
		updateAccountNumber: (
			state,
			action: PayloadAction<{
				fileIndex: number | undefined;
				productLineCode: string | undefined | null;
				newAccountNumber: models["CustomerAccountViewModel"] | null;
			}>
		) => {
			const { fileIndex, productLineCode, newAccountNumber } = action.payload;
			if (fileIndex !== undefined && productLineCode !== undefined && ~fileIndex && productLineCode) {
				(state?.parsedTempCSV ?? state?.parsedCSV)?.forEach((file) => {
					file?.configurations?.forEach((configuration) => {
						if (configuration.globals?.productLine?.code === productLineCode) {
							configuration.accountId = newAccountNumber === null ? null : newAccountNumber?.accountId;
							configuration.accountName =
								newAccountNumber === null ? null : newAccountNumber?.accountName;
							configuration.accountNumber =
								newAccountNumber === null ? null : newAccountNumber?.accountNumber;
						}
					});
				});
			}
		},
		updateBillToNumber: (
			state,
			action: PayloadAction<{
				fileIndex: number | undefined;
				productLineCode: string | undefined | null;
				newBillToNumber: models["BillToViewModel"] | null | undefined;
			}>
		) => {
			const { fileIndex, productLineCode, newBillToNumber } = action.payload;
			if (fileIndex !== undefined && productLineCode !== undefined && ~fileIndex && productLineCode) {
				(state?.parsedTempCSV ?? state?.parsedCSV)?.forEach((file) => {
					file?.configurations?.forEach((configuration) => {
						if (configuration.globals?.productLine?.code === productLineCode) {
							configuration.billToId = newBillToNumber === null ? null : newBillToNumber?.billToId;
						}
					});
				});
			}
		},
		addValidatedOrder: (state, action) => {
			state.validatedOrderResponse = action.payload;
		},
		clearNewOrder: (state) => {
			state.modalStatus = NewOrderModalTracking.NONE;
			state.newAddresses = [];
			state.parsedCSV = undefined;
			state.validatedOrderResponse = undefined;
			state.newGlobalAttributes = undefined;
			state.updatedGlobalAttributes = undefined;
			state.orderType = OrderType.ORIGINAL;
		},
		leaveNewOrderFlow: (state, action) => {
			state.leaveNewOrderFlow = action.payload;
		},
		continueNewOrderFlow: (state) => {
			if (state.leaveNewOrderFlow) {
				state.leaveNewOrderFlow = { ...state.leaveNewOrderFlow, state: "proceed" };
			}
		},
		resetNewOrderFlow: (state) => {
			state.leaveNewOrderFlow = undefined;
		},
		addProductLineAccountInfo: (
			state,
			{ payload: [productLineCode, data] }: PayloadAction<[string, GetAccountsResponseData]>
		) => {
			state.productLineAccounts = {
				...state.productLineAccounts,
				[productLineCode]: data
			};
		},
		updateProductLineAccountInfoShipTos: (state, { payload }: PayloadAction<BillTosShipTosResponseData>) => {
			// Unused now but maybe useful later to update product line billTos with their shipTos
			if (state.productLineAccounts) {
				Object.entries(state.productLineAccounts).forEach(([productLineCode, accounts]) => {
					if (state.productLineAccounts[productLineCode]) {
						state.productLineAccounts[productLineCode] = accounts?.map((account) => ({
							...account,
							billTos: account.billTos?.map((billTo) => ({
								...billTo,
								shipTos: payload?.filter((shipTo) => shipTo?.billToId === billTo?.billToId) ?? []
							}))
						}));
					}
				});
			}
		}
	}
});

export const newOrderActions = newOrderSlice.actions;
export default newOrderSlice.reducer;
