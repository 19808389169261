import styled from "@emotion/styled";

export const AccountInfoBox = styled.div(({ theme }) => ({
	border: theme.project.borders.presets.outline,
	borderWidth: 1,
	display: "flex",
	flexDirection: "column",
	gap: 16,
	padding: 16,
	"& > div:first-of-type": {
		...theme.project.typography.subtitle.subtitle1,
		color: theme.project.colors.fontColors.secondary
	},
	"& > div:nth-of-type(2)": {
		...theme.project.typography.body.body2,
		color: theme.project.colors.fontColors.main
	}
}));
