import { type ChangeEvent, type FC } from "react";
import { ImpersonationBar, NowViewingText, UserWrapper } from "components/Impersonation/impersonation.styles.ts";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { type RootState } from "stores/application.store.tsx";
import { LinkButton } from "components/Common/Link";
import { useTheme } from "@emotion/react";
import { impersonationActions } from "features/reducers/settings/impersonation.ts";
import { UserEmulationChange, UserEmulationNowViewing, UserEmulationSwitchLabel } from "constants/text.ts";

interface BarProps {
	handleModalState(isOpen: boolean): void;
	handleSetReloadKey(): void;
	isModalOpen: boolean;
}

const Bar: FC<BarProps> = ({ handleModalState, handleSetReloadKey, isModalOpen }) => {
	const dispatch = useDispatch();
	const impersonationUser = useSelector((state: RootState) => state.impersonation.impersonationUser);
	const theme = useTheme();

	const handleEmulationChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			handleModalState(true);
		} else if (impersonationUser) {
			dispatch(impersonationActions.setImpersonationUser(undefined));
			handleSetReloadKey();
			handleModalState(false);
		}
	};

	return (
		<ImpersonationBar
			isActive={Boolean(impersonationUser)}
			data-testid="user-impersonation-bar"
		>
			<FormGroup>
				<FormControlLabel
					control={
						<Switch
							data-testid="user-impersonation-toggle"
							onChange={handleEmulationChange}
							checked={Boolean(impersonationUser) || isModalOpen}
						/>
					}
					label={UserEmulationSwitchLabel}
					sx={{ color: theme.project.colors.fontColors.main }}
				/>
			</FormGroup>
			{impersonationUser && (
				<UserWrapper>
					<NowViewingText>
						{UserEmulationNowViewing}: {impersonationUser}
					</NowViewingText>
					<LinkButton
						data-testid="user-impersonation-change-link-button"
						onClick={() => handleModalState(true)}
					>
						{UserEmulationChange}
					</LinkButton>
				</UserWrapper>
			)}
		</ImpersonationBar>
	);
};

export default Bar;
