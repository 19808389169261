import ProgressStepper from "components/Common/Stepper/Stepper";
import {
	NewOrderDetailsGrid,
	NewOrdersHeaderContainer,
	NewOrdersHeaderHead,
	NewOrdersHeaderRow,
	NewOrdersSummaryCounts,
	NewOrdersSummaryDetails,
	OrderTypeContainerMobile,
	OrderTypeMobile,
	OriginalOrderDetailsAccordion
} from "../NewOrders.styles";
import {
	AreYouSureText,
	ItemsWillClearText,
	NoContinueOrdering,
	NoExitOrder,
	OrderAddOns,
	OriginalOrderDetailsText,
	PlaceANewOrderText,
	SaveDraftBeforeLeavingText,
	StartOver,
	UnavailableDataPlaceholderText,
	YesExitOrder,
	YesSaveDraft
} from "constants/text";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { getNewOrderSummaryDetailInfo } from "components/NewOrders/utils/NewOrderUtils.tsx";
import {
	ConfirmationText,
	DraftExitOrder,
	DraftSaveAndExit,
	NewOrdersHeaderSummaryDetailsAccessoriesCount,
	NewOrdersHeaderSummaryDetailsCabinetsCount,
	NewOrdersHeaderSummaryDetailsCubeCount,
	NewOrdersHeaderSummaryDetailsOrderType,
	NewOrdersHeaderSummaryDetailsProductLines,
	NewOrderThanksHeader,
	OrderDefaultText,
	OrderReviewText,
	PriceEstimateText,
	SelectOrderType
} from "../constants";
import { LinkButton } from "../../Common/Link";
import LeaveFlowDialog from "../../Common/LeaveFlowDialog/LeaveFlowDialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import SaveDraftModal from "../SaveDraft/SaveDraftModal";
import { shippingDetailsActions } from "features/reducers/newOrder/shippingDetails.ts";
import ExportButtons from "components/ExportFile/ExportButtons";
import useNewOrdersPDFGenerator from "hooks/useNewOrdersPDFGenerator";
import ExportSnackbar from "components/ExportFile/ExportSnackbar";
import useSnackbarEffect from "hooks/useSnackbarEffect";
import { models } from "types/api/viewModels.ts";
import PlaceOrderOriginalOrderDetails from "components/Common/PlaceOrderOriginalOrderDetails/PlaceOrderOriginalOrderDetails";
import useInitOrderDetail from "hooks/useInitOrderDetail";
import { AccordionDetails, AccordionSummary, FormControl } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { epsilonRounding } from "utils/epsilonRounding.ts";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import { OrderType } from "data/api/v1";
import { capitalizeFirstLetter } from "utils/string.ts";

interface NewOrdersHeaderProps {
	includeDetails?: boolean;
	includeStepper?: boolean;
	isReview?: boolean;
	isConfirmation?: boolean;
	isPDFExport?: boolean;
	isNewOrderPage?: boolean;
	draftOrder?: models["DraftOrderViewModel"];
	isAddOn?: boolean;
}

const NewOrdersHeader = ({
	includeDetails = true,
	includeStepper = true,
	isReview = false,
	isConfirmation = false,
	isPDFExport = false,
	isNewOrderPage = false,
	draftOrder,
	isAddOn = false
}: NewOrdersHeaderProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const dialogState = useSelector((state: RootState) => state.newOrder.leaveNewOrderFlow);
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const orderType = useSelector((state: RootState) => state.newOrder.orderType);
	const submittingNewOrder = useSelector((state: RootState) => state.submittingNewOrder.newOrder);
	const validatedOrderResponse = useSelector((state: RootState) => state.newOrder.validatedOrderResponse);
	const isCareUser = useSelector((state: RootState) => state.impersonation.isCareUser);

	const summaryDetails = useMemo(() => {
		return getNewOrderSummaryDetailInfo(draftOrder ?? parsedCSV?.[0], validatedOrderResponse);
	}, [draftOrder, parsedCSV, validatedOrderResponse]);

	const params = useParams();
	const { orderDetail, orderId } = useInitOrderDetail(params.orderId);
	const isBuildOrderPage = location.pathname.includes("/new-order/build-order");
	const uniqueProductLines = summaryDetails?.productLines?.split(", ");

	const orderDetails = useSelector((state: RootState) => state.submittingNewOrder.reviewOrderDisplay);

	let headerText: string;

	if (isConfirmation) {
		headerText = ConfirmationText;
	} else if (isReview) {
		headerText = OrderReviewText;
	} else {
		headerText = PriceEstimateText;
	}

	const { pdfData, generateNewOrdersPDF } = useNewOrdersPDFGenerator(
		validatedOrderResponse?.configurations,
		headerText,
		validatedOrderResponse?.pricing?.extendedPrice ?? 0,
		`${orderType} ORDER` as OrderType,
		summaryDetails,
		orderDetails,
		isReview,
		isConfirmation,
		orderDetail,
		isAddOn
	);
	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);

	const resetReduxData = (resetFlow = true) => {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
		dispatch(shippingDetailsActions.clearShippingDetails());
		if (resetFlow) dispatch(newOrderActions.resetNewOrderFlow());
		dispatch(newOrderActions.clearTempParsedCSV());
	};

	const handleStartOver = () => {
		if (parsedCSV || submittingNewOrder) {
			dispatch(newOrderActions.leaveNewOrderFlow({ state: "pause" }));
		} else {
			resetReduxData();
			navigate(`/${isAddOn ? "add-ons" : "new-order"}${isAddOn ? "/" + orderId : ""}`);
		}
	};

	const handleDialogExitOrder = () => {
		if (dialogState?.navItem) {
			dispatch(newOrderActions.continueNewOrderFlow());
		} else {
			resetReduxData();
			if (isAddOn) {
				navigate(`/add-ons/${orderId}`);
			} else {
				navigate("/new-order");
			}
		}
		resetReduxData(!dialogState?.navItem);
	};

	const handleDialogClose = () => {
		dispatch(newOrderActions.resetNewOrderFlow());
	};

	const [draftModalIsOpen, setDraftModalIsOpen] = useState(false);
	const handleOpenCloseSaveDraftModalToggle = () => {
		if (draftModalIsOpen) {
			handleDialogExitOrder();
		} else {
			setDraftModalIsOpen(true);
			dispatch(newOrderActions.resetNewOrderFlow());
		}
	};

	const countsByProductLine = validatedOrderResponse?.countsByProductLine ?? {};
	const originalOrderTypes = [OrderType.ORIGINAL, "Display", "Sample"];

	const originalOrderTypesWithAliases = originalOrderTypes.map((orderTypeOption) =>
		orderTypeOption === OrderType.ORIGINAL ? OrderDefaultText : orderTypeOption
	);

	const handleOrderOptionChange = (value: string) => {
		const actualValue = value === OrderDefaultText ? OrderType.ORIGINAL : value;

		dispatch(newOrderActions.updateOrderType(actualValue));
	};

	return (
		<NewOrdersHeaderContainer
			data-testid="new-orders-header"
			isConfirmation={isConfirmation}
			isCareUser={isCareUser}
		>
			{location.pathname !== "/new-order" && (
				<NewOrdersHeaderHead>
					{isConfirmation ? (
						<h5>{NewOrderThanksHeader}</h5>
					) : (
						<div data-testid="new-orders-header-start-over">
							<LinkButton
								hasBackIcon
								onClick={handleStartOver}
								data-testid="new-order-start-over-link"
							>
								{StartOver}
							</LinkButton>
						</div>
					)}
					{isPDFExport && (
						<div>
							<ExportButtons
								largePDF={false}
								generatePDF={generateNewOrdersPDF}
								buttonHeader={"EXPORT " + headerText.toUpperCase()}
								isOnlyPDF
							/>
							<ExportSnackbar
								open={snackbarOpen}
								fileLoading={pdfData.loading}
								largePDF={false}
							/>
						</div>
					)}
				</NewOrdersHeaderHead>
			)}

			<LeaveFlowDialog
				firstButtonText={isAddOn ? YesExitOrder : NoExitOrder}
				dialogState={dialogState !== undefined && !draftModalIsOpen}
				handleDialogExitOrder={handleDialogExitOrder}
				handleDialogClose={handleDialogClose}
				handleOpenDraftModal={isAddOn ? handleDialogClose : handleOpenCloseSaveDraftModalToggle}
				secondButtonText={isAddOn ? NoContinueOrdering : YesSaveDraft}
				subtitle={ItemsWillClearText}
				title={isAddOn ? AreYouSureText : SaveDraftBeforeLeavingText}
			/>

			<SaveDraftModal
				draftOrder={draftOrder}
				saveDraftModalIsOpen={draftModalIsOpen}
				handleSecondaryButton={handleOpenCloseSaveDraftModalToggle}
				closeModal={() => setDraftModalIsOpen(false)}
				firstButtonText={DraftSaveAndExit}
				secondButtonText={DraftExitOrder}
			/>

			{!isConfirmation && (
				<NewOrdersHeaderRow data-testid="new-orders-header-heading">
					<h1 data-testid="new-orders-header-title">{isAddOn ? OrderAddOns : PlaceANewOrderText}</h1>
					{includeStepper && <ProgressStepper data-testid="new-orders-header-stepper" />}
				</NewOrdersHeaderRow>
			)}
			{includeDetails && (
				<NewOrdersSummaryDetails data-testid="new-orders-header-summary-details">
					<NewOrderDetailsGrid>
						<div>{NewOrdersHeaderSummaryDetailsProductLines}</div>
						<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
						<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
						<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
						{!isAddOn && !isBuildOrderPage && <div>{NewOrdersHeaderSummaryDetailsOrderType}</div>}
						{!isAddOn && isBuildOrderPage && (
							<FormControl
								size="small"
								required
								fullWidth
							>
								<AutoComplete
									options={originalOrderTypesWithAliases}
									getOptionLabel={(option) => option}
									onChange={(_event, value) => handleOrderOptionChange(value)}
									value={
										orderType === OrderType.ORIGINAL || orderType === OrderType.UNKNOWN
											? OrderDefaultText
											: capitalizeFirstLetter(orderType)
									}
									isError={false}
									errorText={undefined}
									label={SelectOrderType}
									dataTestId="newOrder-select-order-type-dropdown"
									disableClearable
								/>
							</FormControl>
						)}
					</NewOrderDetailsGrid>
					{validatedOrderResponse?.countsByProductLine ? (
						<>
							{Object.entries(countsByProductLine).map(([productLine, counts]) => (
								<NewOrderDetailsGrid
									key={productLine}
									isNewOrderPage={isNewOrderPage}
								>
									<div>{productLine}</div>
									<div data-testid="new-orders-header-summary-details-cabinets-count">
										{counts?.cabinetCount ?? UnavailableDataPlaceholderText}
									</div>

									<div data-testid="new-orders-header-summary-details-accessories-count">
										{counts?.accessoryCount ?? UnavailableDataPlaceholderText}
									</div>

									<div data-testid="new-orders-header-summary-details-cubes-count">
										{epsilonRounding(Number(counts?.cubeCount ?? UnavailableDataPlaceholderText))
											.toFixed(2)
											.toString()}
									</div>
									{!isAddOn && !isBuildOrderPage && (
										<div data-testid="new-orders-header-summary-order-type">
											{capitalizeFirstLetter(orderType)}
										</div>
									)}
								</NewOrderDetailsGrid>
							))}
							{Object.entries(countsByProductLine).map(([productLine, counts]) => (
								<NewOrdersSummaryCounts key={productLine}>
									<div>
										<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
										<div>{counts?.cabinetCount ?? UnavailableDataPlaceholderText}</div>
									</div>
									<div>
										<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
										<div>{counts?.accessoryCount ?? UnavailableDataPlaceholderText}</div>
									</div>
									<div>
										<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
										<div>
											{epsilonRounding(
												Number(counts?.cubeCount ?? UnavailableDataPlaceholderText)
											)
												.toFixed(2)
												.toString()}
										</div>
									</div>
								</NewOrdersSummaryCounts>
							))}
							<OrderTypeMobile>
								<div>{NewOrdersHeaderSummaryDetailsOrderType}</div>
								<div>{capitalizeFirstLetter(orderType)}</div>
							</OrderTypeMobile>
						</>
					) : (
						<>
							{uniqueProductLines?.map((productLine) => (
								<NewOrderDetailsGrid
									data-testid="new-orders-header-summary-details-product-lines"
									key={productLine}
								>
									<div>{productLine}</div>
									<div>{UnavailableDataPlaceholderText}</div>
									<div>{UnavailableDataPlaceholderText}</div>
									<div>{UnavailableDataPlaceholderText}</div>
								</NewOrderDetailsGrid>
							))}
							{uniqueProductLines?.map((productLine) => (
								<NewOrdersSummaryCounts key={productLine}>
									<div>
										<div>{NewOrdersHeaderSummaryDetailsCabinetsCount}</div>
										<div data-testid="new-orders-header-summary-details-cabinets-count">
											{UnavailableDataPlaceholderText}
										</div>
									</div>
									<div>
										<div>{NewOrdersHeaderSummaryDetailsAccessoriesCount}</div>
										<div data-testid="new-orders-header-summary-details-cubes-count">
											{UnavailableDataPlaceholderText}
										</div>
									</div>
									<div>
										<div>{NewOrdersHeaderSummaryDetailsCubeCount}</div>
										<div data-testid="new-orders-header-summary-details-accessories-count">
											{UnavailableDataPlaceholderText}
										</div>
									</div>
								</NewOrdersSummaryCounts>
							))}
							<OrderTypeContainerMobile>
								{!isAddOn && (
									<FormControl
										size="small"
										required
										fullWidth
									>
										<AutoComplete
											options={originalOrderTypesWithAliases}
											getOptionLabel={(option) => option}
											onChange={(_event, value) => handleOrderOptionChange(value)}
											value={
												orderType === OrderType.ORIGINAL || orderType === OrderType.UNKNOWN
													? OrderDefaultText
													: capitalizeFirstLetter(orderType)
											}
											isError={false}
											errorText={undefined}
											label={SelectOrderType}
											dataTestId="newOrder-select-order-type-dropdown"
											disableClearable
										/>
									</FormControl>
								)}
							</OrderTypeContainerMobile>
						</>
					)}
				</NewOrdersSummaryDetails>
			)}
			{isAddOn && !isConfirmation && (
				<OriginalOrderDetailsAccordion defaultExpanded={location.pathname.includes("add-ons/review-order")}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>{OriginalOrderDetailsText}</AccordionSummary>
					<AccordionDetails>
						<PlaceOrderOriginalOrderDetails orderDetail={orderDetail} />
					</AccordionDetails>
				</OriginalOrderDetailsAccordion>
			)}
		</NewOrdersHeaderContainer>
	);
};

export default NewOrdersHeader;
