import styled from "@emotion/styled";
import DialogContent from "@mui/material/DialogContent";
import { paperClasses } from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";

export const StyledDialog = styled(Dialog)({
	[`.${paperClasses.root}`]: {
		width: 600
	}
});

export const ImpersonationBar = styled.div<{ isActive: boolean }>(({ isActive, theme }) => ({
	backgroundColor: isActive
		? theme.project.colors.backgroundColors.active
		: theme.project.colors.backgroundColors.disabled,
	display: "flex",
	gap: 16,
	padding: "8px 16px",
	position: "sticky",
	top: 0,
	zIndex: 1000
}));

export const EmulationForm = styled(DialogContent)({
	display: "flex",
	flexDirection: "column",
	gap: 16,
	overflow: "visible",
	"& > div:nth-of-type(2)": {
		display: "flex",
		gap: 16,
		justifyContent: "flex-end",
		padding: 0,
		button: {
			margin: 0
		}
	}
});

export const UserWrapper = styled.div(({ theme }) => ({
	alignItems: "center",
	display: "flex",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		overflow: "hidden"
	}
}));

export const NowViewingText = styled.div(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	alignItems: "flex-end",
	color: theme.project.colors.fontColors.main,
	display: "flex",
	height: 24,
	[theme.breakpoints.down("md")]: {
		display: "block",
		height: 20,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	}
}));
