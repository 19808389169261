import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";

export const OrderDetailsFlyoutShadowBlur = styled.div({
	marginBottom: "16px",
	boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)"
});

export const ItemsOnBackorderButton = styled(Button)(({ theme }) => ({
	color: theme.project.colors.allColors.hex.orange.orange500,
	border: "1px solid var(--orange-500)",
	margin: "8px 0",
	width: "100%",
	padding: "4px 8px",
	fontSize: "13px",
	borderRadius: "0",
	"&:hover": {
		border: "1px solid var(--orange-500)"
	},
	svg: {
		height: "22px"
	}
}));

export const OrderDetailsFlyoutResultBackground = styled.div(({ theme }) => ({
	background: theme.project.colors.allColors.hex.grayscale.gray50
}));

export const OrderDetailsFlyoutContainerAlertPartsTableHeader = styled(Grid)({
	width: "55px",
	minHeight: "124px"
});

export const OrderDetailsFlyoutContainerAlertPartsTableHeaderLabel = styled(Grid)({
	width: "70px",
	minHeight: "36px",
	padding: "6px 16px",
	fontWeight: "500",
	lineHeight: "24px",
	"&:first-child": {
		minHeight: "48px"
	}
});

export const OrderDetailsFlyoutContainerAlertPartsTableBody = styled(Grid)({
	width: "243px",
	minHeight: "124px",
	padding: "6px 16px 0px 16px"
});

export const OrderDetailsFlyoutContainerAlertPartsTableBodyValue = styled(Grid)({
	width: "211px",
	minHeight: "36px",
	fontWeight: "400",
	"&:first-child": {
		minHeight: "48px"
	}
});

export const OrderDetailsFlyoutContainerAlertPartsGrid = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "repeat(3, 1fr)",
	borderBottom: theme.project.borders.presets.outline,
	borderWidth: "1px",
	marginBottom: 8,
	padding: "12px 6px"
}));

export const OrderDetailsFlyoutContainerAlertPartsContainer = styled.div({
	padding: 8
});
