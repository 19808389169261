import styled from "@emotion/styled";

export const DimensionContainer = styled.div(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "1fr 1fr",
	gap: 16,
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr"
	},
	"& .MuiInputBase-root": {
		height: 40
	}
}));

export const RequiredCategoryWrapper = styled(DimensionContainer)(({ theme }) => ({
	gridTemplateColumns: "1fr 1fr",
	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr"
	}
}));

export const RequiredSpecificationsWrapper = styled.div({
	display: "flex",
	flexDirection: "column",
	gap: 16
});

export const RequiredDimensionsValues = styled.div(({ theme }) => ({
	...theme.project.typography.caption,
	color: theme.palette.text.secondary,
	marginTop: 3
}));
