import { useState, useEffect } from "react";
import getDetailsModuleAssetUrl from "../mocks/getDetailsModuleAssetUrl";
import { getFileNameBasedOnCurrentDateTime } from "../utils/file";
import { models } from "../types/api/viewModels.ts";

const useOrderDetailsPDFGenerator = (orderDetail: models["OrderDetailViewModel"]) => {
	const [pdfData, setPdfData] = useState<PDFData>({ blob: null, loading: false, error: null });

	const generateOrderDetailsPDF = () => {
		const worker = getDetailsModuleAssetUrl();

		worker.postMessage({ orderDetail });

		worker.onmessage = (e) => {
			if (e.data.blob) {
				setPdfData({ blob: e.data.blob, loading: false, error: null });
			} else if (e.data.error) {
				setPdfData((prevData) => ({ ...prevData, loading: false, error: e.data.error }));
			}
		};

		worker.onerror = (error) => {
			setPdfData((prevData) => ({
				...prevData,
				loading: false,
				error: error instanceof Error ? error : new Error("An unknown error occurred")
			}));
		};

		setPdfData((prevData) => ({ ...prevData, loading: true }));
	};

	const jobName = orderDetail.jobName ?? "";
	const poNumber = orderDetail.poNumber ?? "";
	const filePrefix = `${jobName}_PO${poNumber}`;

	useEffect(() => {
		if (pdfData.blob) {
			const url = window.URL.createObjectURL(pdfData.blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `${getFileNameBasedOnCurrentDateTime(filePrefix)}.pdf`);
			document.body.appendChild(link);
			link.click();
			setPdfData({ blob: null, loading: false, error: null });
		}
	}, [pdfData.blob, jobName, poNumber, filePrefix]);

	return { pdfData, generateOrderDetailsPDF };
};

interface PDFData {
	blob: Blob | null;
	loading: boolean;
	error: Error | null;
}

export default useOrderDetailsPDFGenerator;
