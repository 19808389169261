import { ImportOrderResponseData } from "types/api/orders/importOrder";
import { DesignerViewModel } from "data/api/v1";
import { UnavailableDataPlaceholderText } from "constants/text";
import { newOrderActions } from "features/reducers/newOrder/newOrder.ts";
import { MutableRefObject, useCallback } from "react";
import { useDispatch } from "react-redux";
import { NewOrderSummaryDetailModel } from "../NewOrdersHeader/new-order-summary-details-model";
import { convertToTitleCase } from "utils/string";
import { ValidateProductsResponseData } from "types/api/products/postValidate";
import { models } from "types/api/viewModels.ts";
import { GetAccountsResponseData } from "types/api/accounts/getAccounts.ts";
import { displayDesigner } from "utils/order";
import { v4 as uuidv4 } from "uuid";
import { ConfigurationFileName } from "components/NewOrders/NewOrders.styles.ts";
import { NAOptionText } from "../constants.ts";

export const useCheckForBillToAccountErrors = (
	onSuccess: () => void,
	csvContents: ImportOrderResponseData[] | undefined
) => {
	const dispatch = useDispatch();

	return useCallback(() => {
		let canContinue = true;
		csvContents?.forEach((file, fileIndex) => {
			file?.configurations?.forEach((configuration) => {
				if (!configuration?.accountNumber) {
					dispatch(
						newOrderActions.updateAccountNumber({
							fileIndex: fileIndex,
							productLineCode: configuration.globals?.productLine?.code,
							newAccountNumber: null
						})
					);
					canContinue = false;
				}
				if (!configuration?.billToId && configuration?.accountNumber) {
					dispatch(
						newOrderActions.updateBillToNumber({
							fileIndex: fileIndex,
							productLineCode: configuration.globals?.productLine?.code,
							newBillToNumber: null
						})
					);
					canContinue = false;
				}
			});
		});
		if (canContinue) {
			onSuccess();
		}
	}, [csvContents, dispatch, onSuccess]);
};

export const useCheckForUpdatedItemsBillToAccountErrors = (
	onSuccess: () => void,
	csvContents: ImportOrderResponseData | undefined,
	handleUpdateAccount: (
		account: models["CustomerAccountViewModel"] | null,
		configurationId: string | null | undefined
	) => void,
	handleUpdateBillTo: (
		billTo: models["BillToViewModel"] | null | undefined,
		configurationId: string | null | undefined
	) => void
) => {
	return useCallback(() => {
		let canContinue = true;
		const configurations = csvContents?.configurations;
		if (configurations) {
			for (let i = 0; i < configurations.length; i++) {
				if (!configurations[i]?.accountNumber) {
					handleUpdateAccount(null, configurations[i]?.configurationId);
					canContinue = false;
				}
				if (!configurations[i]?.billToId) {
					handleUpdateBillTo(null, configurations[i]?.configurationId);
					canContinue = false;
				}
				if (!configurations[i]?.billToId && configurations[i]?.accountNumber) {
					handleUpdateBillTo(null, configurations[i]?.configurationId);
					canContinue = false;
				}
			}
		}
		if (canContinue) {
			onSuccess();
		}
	}, [csvContents?.configurations, handleUpdateAccount, handleUpdateBillTo, onSuccess]);
};

export const getNewOrderSummaryDetailInfo = (
	parsedCSV: ImportOrderResponseData | models["DraftOrderViewModel"] | null | undefined,
	validatedOrderResponse: ValidateProductsResponseData | null | undefined
): NewOrderSummaryDetailModel => {
	const configs = validatedOrderResponse?.configurations ?? parsedCSV?.configurations;
	const productLine = new Set(configs?.map((configuration) => configuration.globals?.productLine?.description));

	// Note: Javascript has issues with floating point arithmetic and makes entries like 1.015 multiplied by 100 to 101.49999999...
	// Adding the Number.EPSILON to the cube count before multiplying by 100 addresses this issue
	const cube100x = validatedOrderResponse
		? ((validatedOrderResponse?.counts?.cubeCount ?? 0) + Number.EPSILON) * 100
		: 0;
	const roundedCube = Math.round(cube100x) / 100;
	return {
		productLines: Array.from(productLine).join(", ") || UnavailableDataPlaceholderText,
		accessoryCount: validatedOrderResponse?.counts?.accessoryCount?.toString() ?? UnavailableDataPlaceholderText,
		cabinetCount: validatedOrderResponse?.counts?.cabinetCount?.toString() ?? UnavailableDataPlaceholderText,
		cubeCount: validatedOrderResponse?.counts?.cubeCount
			? roundedCube.toFixed(2)?.toString()
			: UnavailableDataPlaceholderText
	};
};
export const todayDate = new Date();

export const sortGlobalByDescription = (arr: any[]) => {
	return [...arr].sort((a, b) => a.description.localeCompare(b.description));
};

export const addNAOption = (arr: any[]) => {
	const sortedArr = [...arr].sort((a, b) => a.description.localeCompare(b.description));
	const naOption = { description: "N/A" };
	return [naOption, ...sortedArr];
};

export const sortedAccounts = (accounts: GetAccountsResponseData | null | undefined) => {
	if (accounts) {
		accounts = accounts.slice().sort((a, b) => (Number(a?.accountNumber) > Number(b?.accountNumber) ? 1 : -1));
	}
	return accounts;
};

export const sortedBillTos = (billTos: models["BillToViewModel"][] | null | undefined) => {
	if (billTos) {
		billTos = billTos.slice().sort((a, b) => {
			const projectNameA = a?.projectName && a?.projectName.trim() !== "" ? a?.projectName + " - " : "";
			const logisticsModeA = a?.logisticsMode ? " - " + convertToTitleCase(a.logisticsMode) : "";

			const projectNameB = b?.projectName && b?.projectName.trim() !== "" ? b?.projectName + " - " : "";
			const logisticsModeB = b?.logisticsMode ? " - " + convertToTitleCase(b.logisticsMode) : "";

			return String(projectNameA + a?.city + a?.state + logisticsModeA + "-" + a.billToId) >
				String(projectNameB + b?.city + b?.state + logisticsModeB + "-" + b.billToId)
				? 1
				: -1;
		});
	}
	return billTos;
};

export const getProductLineAccountInfo = (
	config: models["ValidatedOrderConfigurationViewModel"] | models["PendingOrderConfigurationViewModel"] | undefined,
	productLineAccountInfoMap: Record<string, GetAccountsResponseData>
): GetAccountsResponseData | undefined => {
	return config ? productLineAccountInfoMap[config?.globals?.productLine?.code ?? ""] : undefined;
};

export const getConfigurationAccountInfo = (
	config: models["ValidatedOrderConfigurationViewModel"] | models["PendingOrderConfigurationViewModel"] | undefined,
	productLineAccountInfoMap: Record<string, GetAccountsResponseData>
): models["CustomerAccountViewModel"] | undefined => {
	return config
		? getProductLineAccountInfo(config, productLineAccountInfoMap)?.find(
				(accountInfo) => accountInfo.accountNumber === config.accountNumber
			)
		: undefined;
};

export const calculateIntervals = (min: number | undefined, max: number | undefined, increment: number | undefined) => {
	if (typeof min !== "undefined" && typeof max !== "undefined" && typeof increment !== "undefined") {
		let arr = [];
		for (let i = min; i <= max; i += increment) {
			arr.push(i);
		}
		if (arr.indexOf(max) === -1) arr.push(max);

		return arr.slice().map((val) => String(val));
	}
	return [];
};

export const sortDesigners = (designers: DesignerViewModel[]): DesignerViewModel[] => {
	return designers.sort((a, b) => {
		const aDisplay = displayDesigner(a);
		const bDisplay = displayDesigner(b);

		const letterOrder = /^[A-Za-z]/;
		const aIsLetter = letterOrder.test(aDisplay[0]);
		const bIsLetter = letterOrder.test(bDisplay[0]);

		if (aIsLetter && !bIsLetter) return -1;
		if (!aIsLetter && bIsLetter) return 1;
		return aDisplay.localeCompare(bDisplay);
	});
};

interface GroupedImportOrderResponseByProductLine {
	[key: string]: ImportOrderResponseData[];
}

// Groups configurations and combines door styles and finishes for display in the import modal
export const getGroupedConfigurations = (
	importedOrders: ImportOrderResponseData[] | null | undefined
): GroupedImportOrderResponseByProductLine =>
	importedOrders?.reduce((accum: GroupedImportOrderResponseByProductLine, current) => {
		current?.configurations?.forEach((config) => {
			const productLineCode = config.globals?.productLine?.code;
			if (productLineCode && !accum[productLineCode]) {
				accum[productLineCode] ??= [{ ...current, configurations: [config] }];
			} else if (productLineCode && accum[productLineCode]) {
				const matchingFileIndex = accum[productLineCode].findIndex(
					(order) => order?.fileName === current.fileName
				);

				if (~matchingFileIndex) {
					accum[productLineCode][matchingFileIndex]?.configurations?.push(config);
				} else {
					accum[productLineCode].push({ ...current, configurations: [config] });
				}
			}
		});

		return accum;
	}, {}) ?? {};

export interface ConfigurationGroup {
	accountId: string | null | undefined;
	accountName: string | null | undefined;
	accountNumber: string | null | undefined;
	billToId: string | null | undefined;
	configurations: (models["PendingOrderConfigurationViewModel"] | models["ValidatedOrderConfigurationViewModel"])[];
	globals: models["GlobalsViewModel"] | undefined;
	id: string;
}

// Groups configurations for line item grid display
export const getConfigurationGroupsByProductLine = (
	configurations:
		| (models["PendingOrderConfigurationViewModel"] | models["ValidatedOrderConfigurationViewModel"])[]
		| null
		| undefined,
	existingGroups?: ConfigurationGroup[]
) => {
	if (!configurations) return;
	const productLineGroups = configurations.reduce((accum: ConfigurationGroup[], current) => {
		if (
			!accum.find((config) => config.globals?.productLine?.code === current.globals?.productLine?.code) &&
			current.globals?.productLine
		) {
			const existingGroupId = existingGroups?.find(
				(group) => group.globals?.productLine?.code === current.globals?.productLine?.code
			)?.id;
			accum.push({
				accountId: undefined,
				accountName: undefined,
				accountNumber: undefined,
				billToId: undefined,
				globals: current.globals,
				configurations: [],
				id: existingGroupId ?? uuidv4()
			});
		}
		return accum;
	}, []);
	configurations.forEach((config) => {
		const groupIndex = productLineGroups.findIndex(
			(group) => group.globals?.productLine?.code === config.globals?.productLine?.code
		);
		if (~groupIndex && productLineGroups[groupIndex]) {
			productLineGroups[groupIndex].configurations.push(config);
			productLineGroups[groupIndex].accountId ??= config.accountId;
			productLineGroups[groupIndex].accountName ??= config.accountName;
			productLineGroups[groupIndex].accountNumber ??= config.accountNumber;
			productLineGroups[groupIndex].billToId ??= config.billToId;
			productLineGroups[groupIndex].globals ??= config.globals;
		}
	});

	return productLineGroups;
};

export const shuffleLineItems = (
	configurations: models["PendingOrderConfigurationViewModel"][],
	selectedRemovedItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>
): models["PendingOrderConfigurationViewModel"][] => {
	let lineItemNumber = 1;
	return configurations.map((config) => ({
		...config,
		lineItems: config?.lineItems
			?.filter((configItem) => configItem.lineItemNumber !== selectedRemovedItem?.current?.lineItemNumber)
			.map((item) => {
				const newModifications: models["PendingModificationViewModel"][] =
					item.modifications?.map((modification, index) => ({
						...modification,
						lineItemNumber: `${lineItemNumber}.${index + 1}`
					})) ?? [];
				const newLineItem: models["PendingLineItemViewModel"] = {
					...item,
					lineItemNumber: String(lineItemNumber),
					modifications: newModifications ?? null
				};
				lineItemNumber += 1;
				return newLineItem;
			})
	}));
};

export const getAttributes = (configuration: models["PendingOrderConfigurationViewModel"] | null | undefined) => {
	return [
		configuration?.globals?.productLine?.description,
		configuration?.globals?.style?.description,
		configuration?.globals?.species?.description,
		configuration?.globals?.finish?.description
	]
		.filter(Boolean)
		.join(", ");
};

export const getSubheaderAttributes = (configuration: models["PendingOrderConfigurationViewModel"]) => {
	return [
		configuration?.globals?.shape?.description,
		configuration?.globals?.profile?.description,
		configuration?.globals?.hingeType?.description,
		configuration?.globals?.case?.description,
		configuration?.globals?.distressing?.description !== NAOptionText &&
			configuration?.globals?.distressing?.description,
		configuration?.globals?.accentColor?.description !== NAOptionText &&
			configuration?.globals?.accentColor?.description,
		configuration?.globals?.accentApplication?.description !== NAOptionText &&
			configuration?.globals?.accentApplication?.description,
		configuration?.globals?.construction?.description,
		configuration?.globals?.packaging?.description
	]
		.filter(Boolean)
		.join(", ");
};

export const getConfigFileName = (
	configuration: models["ValidatedOrderConfigurationViewModel"],
	csvData: (models["ImportedOrderViewModel"] | undefined)[] | undefined
): string | null | undefined => {
	const matchingCSV = csvData?.find((csv) =>
		csv?.configurations?.find((config) => config.configurationId === configuration.configurationId)
	);
	return matchingCSV ? matchingCSV?.fileName : undefined;
};

export const getConfigDisplay = (
	configuration: models["ValidatedOrderConfigurationViewModel"],
	csvData: (models["ImportedOrderViewModel"] | undefined)[] | undefined
) => {
	const fileName = getConfigFileName(configuration, csvData);
	return fileName ? <ConfigurationFileName>{fileName}</ConfigurationFileName> : <></>;
};

export const getLineItemCount = (group: ConfigurationGroup) => {
	const count = group.configurations?.reduce((accum, current) => accum + (current.lineItems?.length ?? 0), 0) ?? 0;
	return `${count} line item${count > 1 ? "s" : ""}`;
};

export const runForAllConfigs = (
	csvContents: ImportOrderResponseData[] | undefined,
	importedCSVs: ImportOrderResponseData[] | undefined,
	callback: (
		reduxConfigIndex: number,
		configIndex: number,
		config: models["PendingOrderConfigurationViewModel"]
	) => void
) => {
	csvContents?.forEach((csv) =>
		csv?.configurations?.forEach((config) => {
			const reduxCSVIndex =
				importedCSVs?.findIndex((importedCSV) => importedCSV?.fileName === csv.fileName) ?? -1;
			const reduxConfigIndex =
				importedCSVs?.[reduxCSVIndex]?.configurations?.findIndex(
					(csvConfig) => csvConfig.configurationId === config.configurationId
				) ?? -1;
			callback(reduxCSVIndex, reduxConfigIndex, config);
		})
	);
};

export const findAccount = (accounts: GetAccountsResponseData | undefined, accountNumber: string | null | undefined) =>
	accounts?.find((account) => account?.accountNumber === accountNumber);

export const findBillTo = (
	selectedAccount: models["CustomerAccountViewModel"] | null | undefined,
	billToId: string | null | undefined
) => selectedAccount?.billTos?.find((billTo) => billTo.billToId === billToId);
