import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";
import { formatSummaryPath, OrderSummaryRequest } from "data/api/v1/requests/getOrderSummaryRequest.ts";
import {
	APIErrorResponse,
	baseQueryWithRedirect,
	encodePostRequest,
	UntransformedResponse
} from "features/api/utils/apiUtils.ts";
import { OrderSummaryViewModel, ReplacementPostModel } from "data/api/v1";
import { SaveDraftRequestBody } from "types/api/orders/postDraftOrder.ts";
import { GetDraftOrdersResponse } from "types/api/orders/getDraftOrders.ts";
import { GetDraftByIdResponse } from "types/api/orders/getDraftById.ts";

export const orderApiSlice = createApi({
	reducerPath: "orderApi",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		getById: builder.query({
			query: (orderId: string) => endpoints.orders.getOrdersById(orderId),
			transformResponse: (response: any) => response?.data,
			transformErrorResponse: (error: { status: string | number }) => ({
				status: error?.status
			})
		}),
		getLinkedOrders: builder.query<OrderSummaryViewModel[], string>({
			query: (orderId: string) => endpoints.orders.getLinkedOrders(orderId)
		}),
		filter: builder.query({
			query: (req: OrderSummaryRequest) => formatSummaryPath(req)
		}),
		createReplacementOrder: builder.mutation({
			query: (replacementOrder) => ({
				url: endpoints.orders.postReplacementOrder(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: encodePostRequest(replacementOrder)
			}),
			transformResponse: (response: { data: ReplacementPostModel }) => response?.data,
			// TO DO ADDRESS BETTER TYPING AROUND ERROR RESPONSE
			transformErrorResponse: (error: any) => ({
				data: {
					errors: error?.data?.errors,
					status: error?.status
				}
			})
		}),
		saveDraftOrder: builder.mutation({
			query: (draftOrder: SaveDraftRequestBody) => ({
				url: endpoints.orders.draftOrders(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: encodePostRequest(draftOrder)
			}),
			transformErrorResponse: (error: any) => ({
				status: error?.status,
				data: {
					errors: error?.data?.errors
				}
			})
		}),
		getDraftOrders: builder.query({
			query: () => endpoints.orders.draftOrders(),
			transformResponse: (response: GetDraftOrdersResponse) => response,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				status: error?.status
			})
		}),
		getTemplates: builder.query({
			query: () => endpoints.orders.getTemplates(),
			transformResponse: (response: GetDraftOrdersResponse) => response,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				status: error?.status
			})
		}),
		getDraftOrder: builder.query({
			query: (draftId: string) => endpoints.orders.draftById(draftId),
			transformResponse: (response: GetDraftByIdResponse) => response,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				status: error?.status
			})
		}),
		deleteDraftOrder: builder.mutation({
			query: (draftId: string) => ({
				url: endpoints.orders.draftById(draftId),
				method: "DELETE"
			})
		}),
		deleteTemplateOrder: builder.mutation({
			query: (templateId: string) => ({
				url: endpoints.orders.templateById(templateId),
				method: "DELETE"
			})
		})
	})
});

export const {
	useGetByIdQuery,
	useCreateReplacementOrderMutation,
	useLazyFilterQuery,
	useGetLinkedOrdersQuery,
	useSaveDraftOrderMutation,
	useLazyGetDraftOrdersQuery,
	useLazyGetTemplatesQuery,
	useDeleteDraftOrderMutation,
	useDeleteTemplateOrderMutation,
	useLazyGetDraftOrderQuery
} = orderApiSlice;
