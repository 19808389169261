import { useDispatch, useSelector } from "react-redux";
import { NewOrdersDialogWrapper } from "../NewOrders.styles";
import { RootState } from "stores/application.store";
import { NewOrderModalTracking } from "data/api/v1";
import AccountBillToDialogForm from "../AccountBillToDialogForm";
import { updateOrderDetails } from "pages/OrderPages/utils/OrderDetailUtil";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CSVUpload from "../CSVUpload";
import { newOrderActions } from "features/reducers/newOrder/newOrder";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder";
import { models } from "types/api/viewModels.ts";

interface NewOrderDialog {
	isAddOn?: boolean;
	orderDetails?: models["OrderDetailViewModel"];
}

const NewOrdersDialog = ({ isAddOn, orderDetails }: NewOrderDialog) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const modalState = useSelector((state: RootState) => state.newOrder.modalStatus);
	const modalStateCSV = modalState === NewOrderModalTracking.CSV_UPLOAD;
	const modalStateAccount = modalState === NewOrderModalTracking.SELECT_BILLTO_ACCOUNT;
	const parsedCSV = useSelector((state: RootState) => state.newOrder.parsedCSV);
	const tempCSVContents = useSelector((state: RootState) => state.newOrder.parsedTempCSV);
	const csvContents = tempCSVContents ?? parsedCSV;
	const orderType = useSelector((state: RootState) => state.newOrder.orderType);

	const handleCloseModal = () => {
		if (location.pathname === "/new-order" || location.pathname === `/add-ons/${params?.orderId}`) {
			dispatch(newOrderActions.clearNewOrder());
		}
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
	};

	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);

	const handleStartOver = () => {
		dispatch(newOrderActions.clearNewOrder());
		dispatch(submittingNewOrderActions.clearOrderToBeSubmitted());
	};

	const handleModalContinue = () => {
		const csvConfigurations = csvContents
			?.map((file) => file?.configurations?.map((config) => config ?? []))
			.filter((file) => typeof file !== "undefined")
			.flatMap((file) => file);
		updateOrderDetails(
			[...(draftOrder?.configurations ?? []), ...(csvConfigurations ?? [])],
			dispatch,
			orderType,
			draftOrder,
			isAddOn,
			params.orderId
		);

		if (isAddOn) {
			navigate(`/add-ons/build-order/${params.orderId}`);
		} else {
			navigate("/new-order/build-order");
		}
		dispatch(newOrderActions.setModalState(NewOrderModalTracking.NONE));
		if (tempCSVContents) {
			dispatch(newOrderActions.overwriteParsedCSV(tempCSVContents));
			dispatch(
				submittingNewOrderActions.addConfigsToDraftOrder(tempCSVContents.flatMap((e) => e?.configurations))
			);
			dispatch(newOrderActions.clearTempParsedCSV());
		}
	};

	return (
		<NewOrdersDialogWrapper
			open={modalStateCSV || modalStateAccount}
			onClose={handleCloseModal}
			disableEscapeKeyDown={modalStateAccount}
			isCSVModal={modalStateCSV}
		>
			{modalStateAccount && (
				<AccountBillToDialogForm
					handleFindOriginalOrder={handleStartOver}
					handleModalContinue={handleModalContinue}
					csvContents={csvContents}
					isAddOn={isAddOn}
					orderDetails={orderDetails}
				/>
			)}

			{modalStateCSV && <CSVUpload handleClose={handleCloseModal} />}
		</NewOrdersDialogWrapper>
	);
};

export default NewOrdersDialog;
