import { InferType, mixed, object } from "yup";
import {
	SelectAccentApplicationError,
	SelectAccentColorError,
	SelectAccountNumberError,
	SelectBillToNumberError,
	SelectCaseColorError,
	SelectConstructionError,
	SelectDistressingError,
	SelectDoorStyleError,
	SelectFinishError,
	SelectHingeError,
	SelectPackagingError,
	SelectProductLineError,
	SelectProfileError,
	SelectShapeError,
	SelectWoodError
} from "../../constants";
import { models } from "types/api/viewModels.ts";

export const schema = object({
	// StepOne
	productLine: mixed<models["ProductLineViewModel"]>().when("$isUpdate", {
		is: false,
		then: (schema) => schema.required(SelectProductLineError)
	}),

	doorStyle: mixed<models["StyleViewModel"]>().required(SelectDoorStyleError),

	// StepTwo
	shape: mixed<models["ShapeViewModel"]>().when("$hasShape", {
		is: true,
		then: (schema) => schema.required(SelectShapeError)
	}),
	profile: mixed<models["CabinetProfileViewModel"]>().when("$hasProfile", {
		is: true,
		then: (schema) => schema.required(SelectProfileError)
	}),
	hinge: mixed<models["HingeTypeViewModel"]>().when("$hasHinge", {
		is: true,
		then: (schema) => schema.required(SelectHingeError)
	}),
	caseColor: mixed<models["CaseViewModel"]>().when("$hasCase", {
		is: true,
		then: (schema) => schema.required(SelectCaseColorError)
	}),
	species: mixed<models["SpeciesViewModel"]>().required(SelectWoodError),
	finish: mixed<models["FinishViewModel"]>().required(SelectFinishError),

	// StepThree
	distressing: mixed<models["DistressingViewModel"]>().when("$hasDistressing", {
		is: true,
		then: (schema) => schema.required(SelectDistressingError)
	}),
	accentColor: mixed<models["AccentColorViewModel"]>().when("$hasAccentColor", {
		is: true,
		then: (schema) => schema.required(SelectAccentColorError)
	}),
	accentApplication: mixed<models["AccentApplicationViewModel"]>().when("$hasAccentApplication", {
		is: true,
		then: (schema) => schema.required(SelectAccentApplicationError)
	}),
	construction: mixed<models["ConstructionViewModel"]>().when("$hasConstruction", {
		is: true,
		then: (schema) => schema.required(SelectConstructionError)
	}),
	packaging: mixed<models["PackagingViewModel"]>().when("$hasPackaging", {
		is: true,
		then: (schema) => schema.required(SelectPackagingError)
	}),

	// StepFour
	accountNumber: mixed<models["CustomerAccountViewModel"]>().when("$isUpdate", {
		is: false,
		then: (schema) => schema.required(SelectAccountNumberError)
	}),
	billToNumber: mixed<models["BillToViewModel"]>().when("$isUpdate", {
		is: false,
		then: (schema) => schema.required(SelectBillToNumberError)
	})
});

export type GlobalAttributeFields = InferType<typeof schema>;
