import OrderListSkeleton from "../Skeletons/OrderListSkeleton";
import styles from "containers/Orders/order-styles.module.css";
import { Box } from "@mui/material";
import NoOrdersErrorBanner from "../NoOrdersErrorBanner/NoOrderErrorBanner";
import { OrderLimitMessageBottom, exportOrdersText } from "constants/text";
import VirtualizedOrderList from "./VirtualizedOrderList";
import { sortBy } from "utils/order";
import ExportSnackbar from "../ExportFile/ExportSnackbar";
import useMyOrdersPDFGenerator from "hooks/useMyOrdersPDFGenerator";
import OrderSummaryAPIFailure from "components/OrderSummaryAPIFailure/OrderSummaryAPIFailure";
import ExportButtons from "../ExportFile/ExportButtons";
import { generateMyOrdersExcel } from "./helpers/MyOrdersExportsHelpers";
import useSnackbarEffect from "hooks/useSnackbarEffect";
import SearchBarFilters from "components/OrderSearch/SearchBarFilters";
import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage";
import { UserDetailsResponse } from "data/api/v1";
import OrderListErrorsWarning from "./OrderListErrorsWarning";
import { OrderListFiltersContainer } from "./OrderList.styles";
import { StyledSearchOrderLimitHelperText } from "components/Form/HelperText/helperText.styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const OrderList = ({
	filteredOrders,
	orders,
	orderStatus,
	isLoading,
	searchFilters,
	searchDates,
	totalStatuses,
	currentSearch,
	isMobile,
	userDetails,
	filterOptions,
	setFilterOptions,
	showOrders,
	hasErrors
}: Props) => {
	const { pdfData, generateMyOrdersPDF } = useMyOrdersPDFGenerator(
		filteredOrders,
		searchFilters,
		searchDates,
		totalStatuses,
		currentSearch
	);
	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);
	const largePDF = filteredOrders.length > 800;

	if (orderStatus !== 200) {
		return (
			<div className={styles.orderSummaryErrorContainer}>
				<OrderSummaryAPIFailure statusCode={orderStatus} />
			</div>
		);
	}

	if (isLoading) {
		return (
			<>
				{Array(3)
					.fill(1)
					.map((_value, index) => (
						<OrderListSkeleton key={index} />
					))}
			</>
		);
	}

	return (
		<div
			data-testid="order-list"
			style={{ margin: "0 1.25rem" }}
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				height="2rem"
			>
				<div
					data-testid="order-list-order-count"
					className={styles.cardTotalOrders}
				>
					Showing {filteredOrders.length} results
				</div>
				{!isLoading && filteredOrders.length > 0 && (
					<ExportButtons
						largePDF={largePDF}
						buttonHeader={exportOrdersText}
						generatePDF={generateMyOrdersPDF}
						generateExcel={() => generateMyOrdersExcel(orders)}
					/>
				)}
			</Box>
			<ExportSnackbar
				open={snackbarOpen}
				fileLoading={pdfData.loading}
				largePDF={largePDF}
			/>
			{showOrders && (
				<OrderListFiltersContainer>
					<SearchBarFilters
						isMobile={isMobile}
						orders={orders}
						userDetails={userDetails}
						filterOptions={filterOptions}
						setFilterOptions={setFilterOptions}
					/>
					{hasErrors && <OrderListErrorsWarning />}
				</OrderListFiltersContainer>
			)}
			{!isLoading && orderStatus !== undefined && orderStatus === 200 && filteredOrders.length === 0 && (
				<NoOrdersErrorBanner data-testid="order-list-no-order-banner" />
			)}
			{filteredOrders.length > 0 && <VirtualizedOrderList items={sortBy(filteredOrders)} />}
			<StyledSearchOrderLimitHelperText
				isLimitError
				hasBackground
			>
				<ErrorOutlineIcon aria-label="Warning" />
				<span>{OrderLimitMessageBottom}</span>
			</StyledSearchOrderLimitHelperText>
		</div>
	);
};

interface Props {
	filteredOrders: any;
	orders: any;
	orderStatus: any;
	isLoading: boolean;
	searchFilters: any;
	searchDates: any;
	totalStatuses: any;
	currentSearch: any;
	isMobile: boolean;
	userDetails?: UserDetailsResponse;
	filterOptions: FilterOpts;
	setFilterOptions: React.Dispatch<React.SetStateAction<FilterOpts>>;
	showOrders: boolean;
	hasErrors: boolean;
}

export default OrderList;
