import { FilterOpts } from "pages/OrderPages/OrderListPage/OrderListPage.tsx";
import { models } from "types/api/viewModels.ts";
import { displayDraftDesigner } from "utils/order";

const getDraftValue = (item: models["DraftOrderViewModel"], key: string) => {
	switch (key) {
		case "account": {
			const accountValue = item.configurations?.map((item) => {
				return `${item.accountNumber} - ${item.accountName?.toUpperCase()}`;
			});

			return accountValue ?? [];
		}
		case "designerName":
			return item?.designerName ?? "Designer not identified";
		default:
			// default is empty because we have accounted for possible cases
			return "";
	}
};

const getCleanFilterValues = (key: string, selectedFilterValues: string[]): string[] => {
	return selectedFilterValues.map((fv: string) => {
		if (fv === "Designer not identified") {
			return "--";
		}
		fv = fv.trim();
		return fv.toUpperCase();
	});
};

export const getFilteredDrafts = (
	filterOptions: FilterOpts,
	drafts: models["DraftOrderViewModel"][]
): models["DraftOrderViewModel"][] => {
	const { designers, accounts } = filterOptions;

	const compare = (key: string, selectedFilterValues: string[], item: models["DraftOrderViewModel"]) => {
		const cleanFilterValues = getCleanFilterValues(key, selectedFilterValues);

		if (key === "designerName") {
			const { designerName, designerNumber } = item;
			const formattedDesigner = displayDraftDesigner(designerName, designerNumber);
			return cleanFilterValues.some(
				(filterValue) => formattedDesigner.toUpperCase() === filterValue.toUpperCase()
			);
		}

		const draftedValues = getDraftValue(item, key);
		if (Array.isArray(draftedValues)) {
			return draftedValues.some((value) => cleanFilterValues.includes(value?.trim()));
		}
		return cleanFilterValues.includes(draftedValues.toUpperCase());
	};

	return (
		Object.entries({
			designerName: designers,
			account: accounts
		})
			?.filter((x) => x[1].length > 0)
			?.reduce((a: any[], c: any) => a?.filter((x) => compare(c[0], c[1], x)), drafts) || []
	);
};
