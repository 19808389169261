import AddIcon from "@mui/icons-material/Add";
import { MutableRefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import {
	AddModificationButton,
	AddModificationRemoveButton,
	AddModificationTypeWrapper,
	ModificationGroup,
	ModificationSkeletonContainer
} from "./AddModificationStyles.ts";
import AutoComplete from "../../Common/Autocomplete/Autocomplete.tsx";
import {
	AddModificationButtonText,
	AddModificationHeader,
	AddModificationIncrementError,
	AddModificationPlaceholder,
	AddModificationRemoveText
} from "./constants.ts";
import { models } from "types/api/viewModels.ts";
import { v4 as uuidv4 } from "uuid";
import Increment from "components/NewOrders/AddModification/components/Increment.tsx";
import { GetModificationsResponseData } from "types/api/products/getModifications.ts";
import {
	ModificationFormErrors,
	ModificationFormValues
} from "components/NewOrders/AddModification/hooks/useAddModification.ts";
import { Skeleton } from "@mui/material";

interface AddModificationProps {
	selectedEditItem: MutableRefObject<models["PendingLineItemViewModel"] | undefined>;
	errors: ModificationFormErrors;
	formValues: ModificationFormValues;
	onModificationChange(values: ModificationFormValues): void;
	handleClearFieldError(errors: ModificationFormErrors): void;
	modifications: GetModificationsResponseData | undefined;
}

const modListIdSymbol = Symbol("modId");

export type Modification = models["ModificationViewModel"] & {
	[modListIdSymbol]: string;
};

export type ModificationValues =
	| (models["ModificationViewModel"] & {
			values?: models["PendingValueViewModel"][] | null;
	  })
	| null
	| undefined;

const AddModification = ({
	onModificationChange,
	formValues,
	handleClearFieldError,
	selectedEditItem,
	errors,
	modifications
}: AddModificationProps) => {
	// The running list of mods, only for adding/removing purposes
	const [modificationList, setModificationList] = useState<Modification[]>([]);

	const [rangeCount, setRangeCount] = useState<number>(0);
	const isLoaded = useRef(false);

	useEffect(() => {
		if (!isLoaded.current && modifications && selectedEditItem.current?.modifications) {
			const modList = selectedEditItem.current.modifications
				?.map((mod) => modifications.find((modByProductLine) => modByProductLine.id === mod.id))
				.filter((mod) => typeof mod !== "undefined")
				.map((mod) => ({ ...mod, [modListIdSymbol]: uuidv4() }));
			if (modList) {
				// Create initial mod list from an edited item's modifications
				setModificationList(modList);

				const map = new Map(formValues);

				// Set initial Autocomplete values from an edited item's modifications
				modList.forEach((mod) => {
					const modValues = [
						...(selectedEditItem.current?.modifications?.find(
							(selectedItemMod) => selectedItemMod.id === mod.id
						)?.values ?? [])
					];

					map.set(mod[modListIdSymbol], { ...mod, values: modValues });
					onModificationChange(map);
				});
				isLoaded.current = true;
			}

			isLoaded.current = true;
		}
	}, [formValues, modifications, onModificationChange, selectedEditItem]);

	const handleModificationChange = (
		_event: SyntheticEvent,
		value: models["ModificationViewModel"] | null | undefined,
		modification: Modification,
		index: number
	) => {
		const errorsMap = new Map(errors);
		errorsMap.set(modification[modListIdSymbol], { selectedMod: false });
		handleClearFieldError(errorsMap);
		onModificationChange(
			new Map(formValues).set(modification[modListIdSymbol], {
				...value,
				values: value?.ranges?.map((range) => ({ description: range.description }))
			})
		);

		setModificationList(
			(prev) =>
				prev
					.map((mod, modIndex) => {
						if (modIndex === index) {
							return { ...value, [modListIdSymbol]: mod[modListIdSymbol] };
						}
						return mod;
					})
					.filter((mod) => typeof mod !== "undefined" && typeof mod !== null) ?? []
		);
	};

	const handleRemoveModification = (index: number) => {
		const valuesMap = new Map(formValues);
		modificationList[index] && valuesMap.delete(modificationList[index][modListIdSymbol]);
		onModificationChange(valuesMap);

		const errorsMap = new Map(errors);
		modificationList[index] && errorsMap.delete(modificationList[index][modListIdSymbol]);
		handleClearFieldError(errorsMap);

		setModificationList((prev) => {
			const listCopy = [...prev];
			listCopy.splice(index, 1);
			return listCopy;
		});
	};

	const handleIncrementChange = (modification: Modification, value: number, rangeIndex: number) => {
		const map = new Map(formValues);
		const modId = modification[modListIdSymbol] ?? "";
		const newValues = map.get(modId)?.values;
		if (newValues && newValues[rangeIndex]) {
			const updatedValues = newValues?.map((rangeValue, index) => {
				if (index === rangeIndex) {
					return { ...rangeValue, value };
				}
				return rangeValue;
			});
			map.set(modId, { ...modification, values: updatedValues });
		}
		onModificationChange(map);

		const errorsMap = new Map(errors);
		const newErrors = errorsMap.get(modification[modListIdSymbol]);
		if (typeof newErrors?.values?.[rangeIndex] !== "undefined") {
			newErrors.values[rangeIndex] = false;
		}
		newErrors && errorsMap.set(modification[modListIdSymbol], newErrors);
	};

	const modificationTypeErrorState = modificationList.map((modification) => modification.description === "");

	useEffect(() => {
		const getRangeCount = document.querySelectorAll("#add-line-item-modification-range-select");
		setRangeCount(getRangeCount.length);
	}, [modificationTypeErrorState]);

	const addModification = () => {
		const newId = uuidv4();
		setModificationList((prev) => [
			...prev,
			{
				[modListIdSymbol]: newId,
				sku: "",
				description: "",
				id: "",
				values: []
			}
		]);

		onModificationChange(new Map(formValues).set(newId, {}));
	};

	const getModificationOptions = (singleMod: Modification) => {
		return [formValues.get(singleMod[modListIdSymbol] ?? ""), ...(modifications ?? [])].filter((mod) => mod?.id);
	};

	return (
		<div>
			<ModificationGroup>
				<h6>{AddModificationHeader}</h6>
				{!isLoaded.current && !modifications && (
					<div>
						<ModificationSkeletonContainer>
							<Skeleton height={24} />
							<Skeleton height={24} />
						</ModificationSkeletonContainer>

						<ModificationSkeletonContainer>
							<Skeleton height={24} />
							<Skeleton height={24} />
						</ModificationSkeletonContainer>

						<Skeleton
							height={24}
							width="50%"
						/>
						<Skeleton
							height={24}
							width="50%"
						/>
					</div>
				)}
				{modificationList.map((singleMod, index) => (
					<div key={singleMod[modListIdSymbol]}>
						<AddModificationTypeWrapper
							key={selectedEditItem?.current?.id}
							multipleRanges={rangeCount > 1}
						>
							<AutoComplete
								options={getModificationOptions(singleMod)}
								onChange={(event, value) => handleModificationChange(event, value, singleMod, index)}
								required
								getOptionLabel={(item) =>
									item?.sku && item?.description ? `${item.sku} - ${item.description}` : ""
								}
								isError={errors.get(singleMod[modListIdSymbol])?.selectedMod}
								errorText={AddModificationIncrementError}
								label={AddModificationPlaceholder}
								dataTestId="add-line-item-modification-select"
								disableClearable
								virtualize
								value={formValues.get(singleMod[modListIdSymbol] ?? "")}
								autoFocus
							/>

							{singleMod?.ranges?.map((rangeData, rangeIndex: number) => (
								<Increment
									key={rangeIndex}
									handleIncrementChange={handleIncrementChange}
									value={formValues.get(singleMod[modListIdSymbol] ?? "")?.values?.[rangeIndex]}
									rangeData={rangeData}
									modification={singleMod}
									rangeIndex={rangeIndex}
									isError={errors.get(singleMod[modListIdSymbol])?.values?.[rangeIndex]}
									id="add-line-item-modification-range-select"
								/>
							))}

							<AddModificationRemoveButton
								variant="remove"
								data-testid="add-line-item-modification-remove-button"
								onClick={() => handleRemoveModification(index)}
							>
								{AddModificationRemoveText}
							</AddModificationRemoveButton>
						</AddModificationTypeWrapper>
					</div>
				))}
				<AddModificationButton
					variant="text"
					data-testid="add-line-item-modification-button"
					onClick={addModification}
				>
					<AddIcon />
					{AddModificationButtonText}
				</AddModificationButton>
			</ModificationGroup>
		</div>
	);
};

export default AddModification;
