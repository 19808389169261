import { createApi } from "@reduxjs/toolkit/query/react";
import endpoints, { baseURL } from "data/api/v1/endpoints.ts";
import {
	APIErrorResponse,
	baseQueryWithRedirect,
	encodePostRequest,
	UntransformedResponse
} from "features/api/utils/apiUtils.ts";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { ImportOrderResponse, ImportOrderRequestBody, ImportOrderResponseData } from "types/api/orders/importOrder";
import {
	ValidateProductsRequestBody,
	ValidateProductsResponse,
	ValidateProductsResponseData
} from "types/api/products/postValidate";
import { v4 as uuidv4 } from "uuid";
import {
	BillTosShipTosRequestBody,
	BillTosShipTosResponse,
	BillTosShipTosResponseData
} from "types/api/accounts/billTosShipTos.ts";
import {
	GetModificationsParameters,
	GetModificationsResponseData,
	GetModificationsResponse
} from "types/api/products/getModifications.ts";
import {
	GetProductsQueryParameters,
	GetProductsResponse,
	GetProductsResponseData
} from "types/api/products/getProducts.ts";

export interface BillToShipToBody {
	accountId: string;
	billToId: string;
}

export const newOrderApiSlice = createApi({
	reducerPath: "newOrderAPI",
	baseQuery: baseQueryWithRedirect(baseURL),
	endpoints: (builder) => ({
		uploadCSV: builder.mutation<ImportOrderResponseData, ImportOrderRequestBody>({
			query: (csvUpload: ImportOrderRequestBody) => ({
				url: endpoints.newOrder.csvUpload(),
				method: "POST",
				formData: true,
				body: csvUpload
			}),
			transformResponse: (response: ImportOrderResponse) => {
				if (!response.data) return;
				return {
					...response.data,
					configurations: response.data?.configurations?.map((config) => ({
						...config,
						configurationId: uuidv4()
					}))
				};
			},
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		validateOrderRequest: builder.mutation<ValidateProductsResponseData, ValidateProductsRequestBody>({
			query: (orderRequest: ValidateProductsRequestBody) => ({
				url: endpoints.newOrder.validateOrderRequest(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: encodePostRequest(orderRequest)
			}),
			extraOptions: {
				handle502Error: true
			},
			transformResponse: (response: ValidateProductsResponse) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		createOrder: builder.mutation({
			query: (order: PostOrdersRequestBody | undefined) => ({
				url: endpoints.newOrder.postNewOrder(),
				method: "POST",
				dataType: "json",
				headers: {
					"Content-type": "application/json; charset=UTF-8"
				},
				body: encodePostRequest(order)
			}),
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		newOrderShipToAddresses: builder.mutation<BillTosShipTosResponseData, BillTosShipTosRequestBody>({
			query: (accountIds: BillTosShipTosRequestBody | undefined) => ({
				url: endpoints.newOrder.getBillToShipTosNewOrders(),
				method: "POST",
				dataType: "json",
				body: accountIds
			}),
			transformResponse: (response: BillTosShipTosResponse) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		getLineItems: builder.query<GetProductsResponseData, GetProductsQueryParameters>({
			query: (params: GetProductsQueryParameters) =>
				endpoints.newOrder.getProductsByProductLineCode(params.productLineCode, params.productId),
			transformResponse: (response: GetProductsResponse) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		}),
		getModifications: builder.query<GetModificationsResponseData, GetModificationsParameters>({
			query: (args: GetModificationsParameters) => {
				const { productId, productLineId } = args;
				return {
					url: endpoints.newOrder.getModifications(productId, productLineId)
				};
			},
			transformResponse: (response: GetModificationsResponse) => response?.data,
			transformErrorResponse: (error: UntransformedResponse<APIErrorResponse>) => ({
				errors: error?.data?.errors,
				status: error?.status
			})
		})
	})
});

export const {
	useCreateOrderMutation,
	useUploadCSVMutation,
	useValidateOrderRequestMutation,
	useNewOrderShipToAddressesMutation,
	useGetLineItemsQuery,
	useGetModificationsQuery
} = newOrderApiSlice;
