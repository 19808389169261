import { type FC, Ref } from "react";
import { JSX } from "@emotion/react/jsx-runtime";
import { Interpolation, Theme } from "@emotion/react";
import TextField, {
	FilledTextFieldProps,
	OutlinedTextFieldProps,
	StandardTextFieldProps,
	TextFieldVariants
} from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";

interface DefaultInputProps {
	params: JSX.IntrinsicAttributes & { css?: Interpolation<Theme> } & {
		variant?: TextFieldVariants | undefined;
	} & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">;
	isError: boolean;
	label: string;
	required: boolean;
	dataTestId: string;
	autoFocus?: boolean;
	isLoading?: boolean;
	inputRef?: Ref<HTMLInputElement | undefined>;
}

const DefaultInput: FC<DefaultInputProps> = ({
	params,
	isError,
	label,
	required,
	dataTestId,
	autoFocus,
	isLoading,
	inputRef
}) => {
	return (
		<TextField
			{...params}
			error={isError}
			label={
				<span>
					{label}
					{required && (
						<span
							style={{
								marginLeft: "2px"
							}}
						>
							*
						</span>
					)}
				</span>
			}
			data-testid={dataTestId}
			variant="outlined"
			autoFocus={autoFocus}
			inputRef={inputRef}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{isLoading ? (
							<CircularProgress
								color="inherit"
								size={20}
								data-testid="loading-spinner"
							/>
						) : null}
						{params.InputProps?.endAdornment}
					</>
				),
				style: { fontSize: 16 }
			}}
		/>
	);
};

export default DefaultInput;
