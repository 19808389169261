import styled from "@emotion/styled";
import { Box, Chip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StyledSummaryCardLogo from "../../styles/StyledComponents/StyledSummaryCardLogo.tsx";

export const OrderDetailsHeaderWrapper = styled.div(({ theme }) => ({
	width: "calc(100% - 40px)",
	backgroundColor: theme.palette.background.default,
	padding: 20
}));

export const OrderDetailsMyOrdersLink = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	width: "100%",
	marginBottom: 16
});

export const OrderDetailsHeaderContent = styled.div(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "flex-start",
	gap: 16,
	width: "100%",
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		marginBottom: 16
	}
}));

export const OrderDetailsHeaderContentDetails = styled.div({
	display: "flex",
	flexDirection: "column",
	alignItems: "flex-start"
});

export const OrderDetailsHeaderJobName = styled.div({
	display: "flex",
	alignItems: "center",
	gap: 12,
	h4: {
		margin: 0,
		wordBreak: "break-all"
	}
});

export const OrderDetailsGrayBox = styled.div(({ theme }) => ({
	minWidth: "568px",
	backgroundColor: theme.palette.grey[100],
	padding: "8px 16px",
	marginTop: 16,
	p: {
		lineHeight: "32px",
		margin: 0
	},
	div: {
		display: "flex",
		marginTop: 5,
		flexDirection: "column",
		alignItems: "flex-start",

		[theme.breakpoints.down("md")]: {
			marginTop: 0
		}
	},
	[theme.breakpoints.down("lg")]: {
		minWidth: "calc(100% - 28px)"
	}
}));

export const StatusReason = styled.div<{ statusReasonColor: string }>(({ theme, statusReasonColor }) => ({
	color: statusReasonColor,
	[theme.breakpoints.down("lg")]: {
		minWidth: "calc(100% - 28px)"
	},
	[theme.breakpoints.down("md")]: {
		marginBottom: 8
	}
}));

export const ReplacementOrderSubmittedBanner = styled.div({
	backgroundColor: "var(--cwg-blue-50)",
	width: "calc(100% - 20px)",
	marginTop: "1rem",
	color: "var(--cwg-blue-900)",
	fontFamily: "Gibson Medium",
	position: "relative",
	padding: ".875rem 1.5rem .875rem 0",
	display: "flex",
	alignItems: "center",
	letterSpacing: ".15px",
	"& > svg": {
		width: "1.375rem",
		height: "1.375rem",
		position: "absolute",
		top: ".8125rem",
		left: "1rem"
	},
	p: {
		margin: "0 0 0 3.125rem"
	},
	"> div > p:last-of-type": {
		fontSize: "var(--font-size-3)",
		marginTop: ".5rem"
	}
});

export const AssociatedOrdersData = styled.div({
	margin: "0.5rem 0 0.5rem 0",
	wordBreak: "break-all"
});

export const ArrowContainer = styled.div({
	display: "flex",
	alignItems: "center",
	justifyContent: "end"
});

export const AssociatedOrdersHeader = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	height: "3rem",
	alignItems: "end",
	marginBottom: "12px",
	[theme.breakpoints.down("lg")]: {
		height: "6.5rem",
		alignItems: "end"
	}
}));

export const StatusContainer = styled.span(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "auto 30px auto",
	color: theme.palette.text.primary,
	[theme.breakpoints.down("md")]: {
		display: "unset"
	}
}));

export const OrderNumberPoNumberText = styled.span(({ theme }) => ({
	...theme.project.typography.subtitle.subtitle2,
	marginRight: 8
}));

export const OrderNumberPoNumberWrapper = styled.span(({ theme }) => ({
	...theme.project.typography.body.body2
}));

export const OrderNumberPoNumberDivider = styled.span({
	display: "flex",
	justifyContent: "center"
});

export const StatusWrapper = styled.span(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: 8,
	gridTemplateColumns: "auto 30px auto",
	[theme.breakpoints.down("md")]: {
		alignItems: "flex-start",
		flexDirection: "column"
	}
}));

export const ButtonContainer = styled.div(({ theme }) => ({
	[theme.breakpoints.down("md")]: {
		width: "100%"
	},

	button: {
		width: 215,
		marginTop: 8,
		[theme.breakpoints.down("md")]: {
			width: "inherit"
		}
	}
}));

interface OrderHeaderProps {
	margin?: boolean;
}
export const AssociatedOrdersHeading = styled.h1<OrderHeaderProps>(({ margin }) => ({
	fontFamily: "Gibson Regular, sans-serif",
	fontSize: "var(--font-size-7)",
	margin: margin ? "0 0 0 1rem" : 0,
	fontWeight: "var(--font-weight-regular)",
	lineHeight: "var(--line-height-3)",
	letterSpacing: "var(--letter-spacing-2)",
	color: "var(--text-primary)"
}));

export const XIconContainer = styled.div({
	display: "flex",
	paddingRight: "5px"
});

export const ArrowForward = styled.span({
	transform: "scale(0.6)"
});

export const NumberOfOrders = styled.div({
	margin: "0 0 0 1rem",
	fontFamily: "Gibson",
	fontSize: 12,
	fontWeight: 400,
	letterSpacing: 1,
	color: "var(--text-primary)",
	height: "3rem",
	display: "flex",
	alignItems: "center"
});

export const DrawerContainer = styled.div({
	width: "375px",
	overflowY: "hidden"
});

export const EmptyOrdersContainer = styled.div({
	display: "grid",
	justifyContent: "center",
	marginTop: "35%",

	h2: {
		fontFamily: "Gibson Regular, sans-serif",
		fontSize: "var(--font-size-7)",
		fontStyle: "normal",
		fontWeight: 500,
		color: "var(--cwg-blue-900)",
		letterSpacing: "var(--letter-spacing-7)",
		margin: "0 0 1rem 0",
		textAlign: "center"
	},
	img: {
		height: "200px",
		display: "flex",
		justifyContent: "center",
		width: "95%"
	}
});

export const EmptyOrdersText = styled.p({
	margin: "0",
	padding: "0 1rem 0 1rem",
	fontFamily: "Gibson Regular, sans-serif",
	fontWeight: "var(--font-weight-regular)",
	lineHeight: "var(--line-height-5)",
	fontSize: "var(--font-size-5)",
	letterSpacing: "var(--letter-spacing-4)",
	color: "var(--text-secondary)",
	textAlign: "center"
});

export const CardContainer = styled.div({
	border: "1px solid var(--gray-400)",
	margin: "0 1rem 1rem 1rem",
	padding: "1rem",
	overflowX: "hidden",
	cursor: "pointer",
	backgroundColor: "var(--gray-100)"
});

export const CardContainerGrid = styled.div({
	display: "grid",
	gridTemplateColumns: "1fr 50px"
});

export const CardHeader = styled.div({
	display: "flex",
	alignItems: "center",
	minHeight: 30,
	height: "auto",

	h2: {
		margin: "0 0 0.5rem 0",
		fontFamily: "Gibson Medium, sans-serif",
		fontWeight: "var(--font-weight-medium)",
		lineHeight: "var(--line-height-4)",
		fontSize: "var(--font-size-6)",
		letterSpacing: "var(--letter-spacing-4)",
		paddingRight: "0.5rem",
		wordBreak: "break-word"
	}
});

interface CardDataStyleProps {
	minWidth?: string;
}

export const CardDataStyles = styled.span<CardDataStyleProps>(({ minWidth }) => ({
	margin: "0 12px 0 0",
	minWidth: minWidth
}));

export const OrderETA = styled.h5({
	color: "var(--text-primary)",
	margin: 0,
	whiteSpace: "nowrap"
});

export const StyledChip = styled(Chip)(({ theme }) => ({
	color: theme.palette.text.primary,
	fontWeight: 500,
	height: 24,
	marginTop: "6px",
	"& .MuiChip-label": {
		fontSize: "var(--font-size-4)",
		letterSpacing: 0.46
	}
}));

export const EstimatedDeliveryToolTip = styled(InfoOutlinedIcon)(({ theme }) => ({
	width: 16,
	marginLeft: 4,
	color: theme.project.colors.primaryColors.main,
	cursor: "pointer"
}));

export const EstimatedDelivery = styled.h6(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: 1,
	margin: 0
}));

export const DetailsSummaryCardLogo = styled(StyledSummaryCardLogo)(({ theme }) => ({
	height: 42
}));

export const CarrierInfoContainer = styled.span(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	[theme.breakpoints.down("md")]: {
		display: "flex",
		flexDirection: "column",
		width: "auto",
		"& > div > div:first-of-type": {
			marginBottom: 8
		}
	},

	div: {
		width: 215,
		wordBreak: "break-word"
	}
}));

export const TrackingNumberWrapper = styled.a(({ theme }) => ({
	color: theme.project.colors.primaryColors.main
}));

export const RequestedDateContainer = styled.span(({ theme }) => ({
	...theme.project.typography.caption,
	display: "flex",
	alignItems: "flex-end",
	gap: 8,
	color: theme.project.colors.fontColors.secondary
}));

export const DeliveryContent = styled.span(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	marginTop: 8,
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		"& > div:nth-of-type(1)": {
			marginBottom: 8
		}
	}
}));

export const PillContainer = styled.span(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	gap: 8,
	marginTop: 8,
	[theme.breakpoints.down("lg")]: {
		alignItems: "baseline"
	},
	[theme.breakpoints.down("md")]: {
		flexDirection: "column",
		alignItems: "flex-start",
		gap: 0
	}
}));

export const OrderDateColumn = styled.div({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	marginBottom: 8
});
